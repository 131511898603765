import { useNavigate } from "react-router-dom";

export const useErrorPage = () => {
  const navigate = useNavigate();

  const navigateToErrorPage = (message: string) => {
      navigate('/error', { state: { message } })
  };

  return {
    navigateToErrorPage,
  };
};
