import { Column, Text } from "@components";
import { Food } from "@modules/Foods/types";
import { IngredientFormData, RecipeFormData } from "@modules/Recipes/types";
import { FC, useMemo } from "react";
import { Control, UseFieldArrayAppend, UseFormRegister } from "react-hook-form";
import { IngredientAutocomplete, IngredientList } from "./components";

export interface IngredientEditListProps {
  ingredients: IngredientFormData[];
  foods: Food[];
  onAdd: UseFieldArrayAppend<RecipeFormData, "ingredients">;
  onRemove: (index: number) => void;
  register: UseFormRegister<RecipeFormData>;
  control: Control<RecipeFormData, any>;
}

export const IngredientEditList: FC<IngredientEditListProps> = ({
  ingredients,
  foods,
  onAdd,
  onRemove,
  register,
  control,
}) => {
  const handleSelectFood = (food: Food) => {
    const unit = food.units.find((unit) => unit.name === "g");
    if (unit) {
      onAdd({
        food,
        unit: {
          id: unit.id,
        },
        amount: 1,
      });
    }
  };

  const filteredFoods = useMemo(
    () =>
      foods.filter(
        (food) =>
          !ingredients.some((ingredient) => ingredient.food.id === food.id)
      ),
    [foods, ingredients]
  );

  return (
    <Column>
      <Text>Ingredients</Text>
      <IngredientAutocomplete
        foods={filteredFoods}
        onSelect={handleSelectFood}
      />
      <IngredientList
        ingredients={ingredients}
        onRemove={onRemove}
        control={control}
        register={register}
      />
    </Column>
  );
};
