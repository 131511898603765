import {
  // FoodDialog,
  RecipesList as RecipesListComponent,
} from "@modules/Recipes/components";
import { FC } from "react";
// import { useDeleteFood } from "@modules/Foods/hooks/useDeleteFood/useDeleteFood";
// import { Food } from "@modules/Foods/types";
// import { useUpdateFood } from "@modules/Foods/hooks/useUpdateFood/useUpdateFood";
import { useGetRecipes } from "@modules/Recipes/hooks";

export interface RecipesListProps {}

export const RecipesList: FC<RecipesListProps> = () => {
  const { recipes, isLoading } = useGetRecipes();
  // const { updateFood } = useUpdateFood();
  // const { deleteFood } = useDeleteFood();
  return (
      <RecipesListComponent
        items={recipes}
        pending={isLoading}
        // onDelete={deleteFood}
        // onEdit={setEditFood}
      />
  );
};
