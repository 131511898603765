import { Column, IconButton, Row, Select, Text } from "@components";
import { IngredientFormData, RecipeFormData } from "@modules/Recipes/types";
import { Photo, Remove } from "@mui/icons-material";
import { Avatar, Input, InputAdornment, MenuItem } from "@mui/material";
import { FC } from "react";
import { Control, UseFormRegister } from "react-hook-form";

export interface IngredientListProps {
  ingredients: IngredientFormData[];
  onRemove: (index: number) => void;
  register: UseFormRegister<RecipeFormData>;
  control: Control<RecipeFormData, any>;
}

export const IngredientList: FC<IngredientListProps> = ({
  ingredients,
  register,
  control,
  onRemove,
}) => {
  const handleRemove = (index: number) => () => {
    onRemove(index);
  };

  return (
    <Column>
      {ingredients.map((ingredient, i) => {
        const imageSrc =
          ingredient.food.images.length > 0
            ? ingredient.food.images[0].src
            : "";
        return (
          <Row key={i} sx={{ justifyContent: "space-between", mt: 2, mb: 1 }}>
            <Avatar src={imageSrc} sx={{ alignSelf: "flex-end" }}>
              <Photo />
            </Avatar>
            <Column sx={{ flex: 1, ml: 1, mr: 1 }}>
              <Text>{ingredient.food.name}</Text>
              <Row sx={{ alignItems: "flex-end" }}>
                <Input
                  type="number"
                  margin="dense"
                  size="small"
                  sx={{ width: 100 }}
                  inputProps={{
                    step: '0.1'
                  }}
                  {...register(`ingredients.${i}.amount`, {
                    required: "Amount required",
                    min: {
                      value: 0.1,
                      message: "Value must be greater than 0",
                    },
                    valueAsNumber: true,
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <Text sx={{ pr: 2, pb: 5 / 8 }}>x</Text>
                    </InputAdornment>
                  }
                />
                <Select
                  id={`ingredients.${i}.unit.id`}
                  name={`ingredients.${i}.unit.id`}
                  control={control}
                  rules={{
                    required: "Unit required",
                  }}
                >
                  {ingredient.food.units.map((unit) => (
                    <MenuItem key={unit.id} value={unit.id}>
                      {unit.name}
                    </MenuItem>
                  ))}
                </Select>
              </Row>
            </Column>
            <IconButton color="error" onClick={handleRemove(i)}>
              <Remove />
            </IconButton>
          </Row>
        );
      })}
    </Column>
  );
};
