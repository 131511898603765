import { useAccessTokenContext } from "@contexts";
import { isAccessTokenValid } from "@utils/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useCheckAccessToken = () => {
  const navigate = useNavigate();
  const { accessToken } = useAccessTokenContext();

  useEffect(() => {
    if (isAccessTokenValid(accessToken)) {
      navigate("/", { replace: true });
    }
  }, [accessToken, navigate]);
};
