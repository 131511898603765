import {
  ListItemText as MaterialListItemText,
  ListItemTextProps as MaterialListItemTextProps,
} from "@mui/material";
import { FC } from "react";

export interface ListItemTextProps extends MaterialListItemTextProps {}

export const ListItemText: FC<ListItemTextProps> = ({
  primary,
  secondary,
  primaryTypographyProps,
  secondaryTypographyProps,
  ...props
}) => {
  return (
    <MaterialListItemText
      primary={primary}
      secondary={secondary}
      primaryTypographyProps={{
        sx: { color: "text.primary", ...primaryTypographyProps?.sx },
        ...primaryTypographyProps,
      }}
      secondaryTypographyProps={{
        sx: { color: "text.primary", ...secondaryTypographyProps?.sx },
        ...secondaryTypographyProps,
      }}
      {...props}
    />
  );
};
