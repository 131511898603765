import { ConfirmData, useConfirmContext } from "@contexts";

export const useConfirm = () => {
  const { setData, resetData, onCancel, onConfirm, ...data } = useConfirmContext();

  const openConfirm = (data: ConfirmData) => {
    setData(data)
  };

  const handleConfirm = () => {
    resetData();
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleCancel = () => {
    resetData();
    if (onCancel) {
      onCancel();
    }
  };

  return {
      ...data,
    openConfirm,
    onConfirm: handleConfirm,
    onCancel: handleCancel,
  };
};
