import { FC, forwardRef } from "react";
import { TextField, TextFieldProps } from "@components";

export type EmailFieldProps = TextFieldProps & {};

export const EmailField: FC<EmailFieldProps> = forwardRef(
  ({ label = "Email", ...props }, ref) => {
    return <TextField ref={ref} type="email" label={label} {...props} />;
  }
);
