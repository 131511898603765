import { Box, BoxProps, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";

export interface PageProps extends BoxProps {}

export const Page: FC<PageProps> = ({ sx, children, ...props }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      {isDesktop && <h1>My Food App</h1>}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          height: isDesktop ? "80%" : "100%",
          width: isDesktop ? 400 : "100%",
          bgcolor: "background.paper",
          boxShadow: isDesktop ? 5 : 0,
          ...sx,
        }}
        {...props}
      >
        {children}
      </Box>
    </Box>
  );
};
