import MyFitAppApi, { CreateFoodData } from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useQueryClient, useMutation } from "react-query";

export const useCreateFood = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, CreateFoodData>(
    (data: CreateFoodData) => MyFitAppApi.createFood({ accessToken, data }),
    {
      onSuccess: () => {
        showSuccess({ text: "food was created successfully" });
        queryClient.invalidateQueries("foods");
      },
      onError: () => {
        showError({ text: "food could not be created" })
      },
    }
  );

  return {
    ...mutation,
    createFood: (data: CreateFoodData) => mutate(data),
  };
};
