import { CreateFoodData, UpdateFoodData } from "@apis/MyFitApp";
import { Dialog } from "@components";
import { FoodFormData } from "@modules/Foods/types";
import { FC } from "react";
import { FoodForm } from "./components";

export interface FoodDialogProps {
  open: boolean;
  onCreate?: (data: CreateFoodData) => void;
  onUpdate?: (data: UpdateFoodData) => void;
  onClose: () => void;
  food?: FoodFormData;
}

export const FoodDialog: FC<FoodDialogProps> = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  food,
}) => {
  const handleCreate = (data: CreateFoodData) => {
    onClose();
    if (onCreate) {
      onCreate(data);
    }
  };

  const handleUpdate = (data: UpdateFoodData) => {
    onClose();
    if (onUpdate) {
      onUpdate(data);
    }
  };

  return (
    <Dialog open={open} onBackdropClick={onClose}>
      <FoodForm
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        defaultValues={food}
      />
    </Dialog>
  );
};
