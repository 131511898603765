import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { DEFAULT_MACROS, Macros } from "@modules/Foods/types";
import { CategoryLogs } from "@modules/Log/types";
import { useQuery } from "react-query";

export type UseGetLogsConfig = {
  date: string;
};

const defaultLogs = {};

export const useGetLogs = ({ date }: UseGetLogsConfig) => {
  const { accessToken } = useAccessTokenContext();
  const { data = { logs: defaultLogs, macros: DEFAULT_MACROS }, ...results } = useQuery<{
    logs: CategoryLogs;
    macros: Macros;
  }>(["logs", date], () =>
    MyFitAppApi.getLogs({
      accessToken,
      date,
    })
  );

  const { logs, macros } = data;

  return { ...results, logs, macros };
};
