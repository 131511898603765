import { FC } from "react";
import {
  TabsProps as MaterialTabsProps,
  Tabs as MaterialTabs,
} from "@mui/material";

export interface TabsProps extends MaterialTabsProps {}

export const Tabs: FC<TabsProps> = ({ children, ...props }) => {
  return <MaterialTabs {...props}>{children}</MaterialTabs>;
};
