import {
  TextField as MaterialTextField,
  TextFieldProps as MaterialTextFieldProps,
} from "@mui/material";
import { FC, forwardRef } from "react";

export type TextFieldProps = MaterialTextFieldProps & {};

export const TextField: FC<TextFieldProps> = forwardRef(
  ({ helperText, inputProps, ...props }, ref) => {
    return (
      <MaterialTextField
        ref={ref}
        margin="normal"
        inputProps={{ role: "textbox", ...inputProps }}
        error={typeof helperText === "string"}
        helperText={helperText}
        {...props}
      />
    );
  }
);