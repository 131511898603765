import { CreateFoodData, UpdateFoodData } from "@apis/MyFitApp";
import { Button, Form, TextField } from "@components";
import { FoodFormData } from "@modules/Foods/types";
import { FC } from "react";
import { UnitEditList } from "../UnitEditList/UnitEditList";
import { FoodDietToggleButton, MacrosInput } from "./components";
import { useFoodForm } from "./hooks";

export interface FoodFormProps {
  onCreate: (food: CreateFoodData) => void;
  onUpdate: (food: UpdateFoodData) => void;
  defaultValues?: FoodFormData;
}

export const FoodForm: FC<FoodFormProps> = ({
  onCreate,
  onUpdate,
  defaultValues,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    addUnit,
    removeUnit,
    changeDiet,
    calories,
    units,
    diet,
  } = useFoodForm({ defaultValues, onCreate, onUpdate });

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        helperText={errors?.name?.message}
        {...register("name", { required: "Name required" })}
      />
      <MacrosInput register={register} errors={errors} calories={calories} />
      <FoodDietToggleButton value={diet} onChange={changeDiet} />
      <UnitEditList
        units={units}
        register={register}
        onAdd={addUnit}
        onRemove={removeUnit}
      />
      <Button type="submit" sx={{ mt: 2 }}>
        SAVE
      </Button>
    </Form>
  );
};
