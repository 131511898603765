import { Text, Page } from "@components";
import { LoginForm, useCheckAccessToken } from "@modules/Auth";

const LoginPage = () => {
  useCheckAccessToken();

  return (
    <Page sx={{ p: 2 }}>
      <Text variant="h5" align="center" sx={{ mt: 4, mb: 4 }}>
        MyFoodApp
      </Text>
      <LoginForm />
    </Page>
  );
};

export default LoginPage;
