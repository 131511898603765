import { CreateRecipeLogData } from "@apis/MyFitApp/logs/logs.types";
import { Button, Form, TextField } from "@components";
import { FoodLogTiming, RecipeLogFormData } from "@modules/Log/types";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FC } from "react";
import { useForm } from "react-hook-form";

const TIMING_OPTIONS = [
  {
    label: "Pre Workout",
    value: "PRE_WORKOUT",
  },
  {
    label: "Post Workout",
    value: "POST_WORKOUT",
  },
];

export interface RecipeLogFormProps {
  defaultValues?: RecipeLogFormData;
  onCreate: (data: CreateRecipeLogData) => void;
}

export const RecipeLogForm: FC<RecipeLogFormProps> = ({
  defaultValues,
  onCreate,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<RecipeLogFormData>({ defaultValues });

  const timing = watch("timing");

  const handleChangeTiming = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    timing: FoodLogTiming
  ) => {
    setValue("timing", timing ? timing : null);
  };

  return (
    <Form onSubmit={handleSubmit(onCreate)}>
      <TextField
        label="Amount"
        type="number"
        inputProps={{
          step: 0.1,
        }}
        helperText={errors?.amount?.message}
        {...register("amount", {
          required: "Amount required",
          min: {
            value: 0.1,
            message: "Must be greater than 0",
          },
          valueAsNumber: true,
        })}
      />
      <ToggleButtonGroup
        size="small"
        onChange={handleChangeTiming}
        sx={{ mt: 2, mb: 1 }}
        value={timing}
        exclusive
      >
        {TIMING_OPTIONS.map((option) => (
          <ToggleButton
            key={option.value}
            value={option.value}
            sx={{ textTransform: "none", flex: 1 }}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Button type="submit" sx={{ mt: 2, mb: 1 }}>
        SAVE
      </Button>
    </Form>
  );
};
