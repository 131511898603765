import {
  CreateCustomLogData,
} from "@apis/MyFitApp/logs/logs.types";
import { Button, Form, Row, TextField, Text } from "@components";
import { CustomLogFormData, FoodLogTiming } from "@modules/Log/types";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

const TIMING_OPTIONS = [
  {
    label: "Pre Workout",
    value: "PRE_WORKOUT",
  },
  {
    label: "Post Workout",
    value: "POST_WORKOUT",
  },
];

export interface CustomLogFormProps {
  // foodUnits: FoodUnit[];
  defaultValues?: CreateCustomLogData;
  onCreate: (data: CreateCustomLogData) => void;
  // onUpdate: (data: UpdateFoodLogData) => void;
}

export const CustomLogForm: FC<CustomLogFormProps> = ({
  defaultValues,
  onCreate,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CustomLogFormData>({ defaultValues });

  const timing = watch("timing");

  const handleChangeTiming = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    timing: FoodLogTiming
  ) => {
    setValue("timing", timing ? timing : null);
  };

  const submit: SubmitHandler<CustomLogFormData> = (data) => {
    if (data.id !== undefined) {
      // onUpdate({ ...data, id: data.id });
    } else {
      onCreate(data);
    }
  };

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <TextField
        label="Name"
        helperText={errors?.name?.message}
        {...register("name", { required: "Name required" })}
      />
      <Row sx={{ height: 32 }}>
        <Text>Macros</Text>
      </Row>
      <Row sx={{ alignItems: "flex-start" }}>
        <TextField
          label="Protein"
          type="number"
          helperText={errors?.protein?.message}
          inputProps={{
            step: 0.1,
          }}
          {...register("protein", {
            required: "Protein required",
            valueAsNumber: true,
          })}
        />
        <TextField
          label="Carbs"
          type="number"
          helperText={errors?.carbs?.message}
          inputProps={{
            step: 0.1,
          }}
          {...register("carbs", {
            required: "Carbs required",
            valueAsNumber: true,
          })}
        />
        <TextField
          label="Fats"
          type="number"
          helperText={errors?.fats?.message}
          inputProps={{
            step: 0.1,
          }}
          {...register("fats", {
            required: "Fats required",
            valueAsNumber: true,
          })}
        />
      </Row>
      <ToggleButtonGroup
        size="small"
        onChange={handleChangeTiming}
        sx={{ mt: 2, mb: 1 }}
        value={timing}
        exclusive
      >
        {TIMING_OPTIONS.map((option) => (
          <ToggleButton
            key={option.value}
            value={option.value}
            sx={{ textTransform: "none", flex: 1 }}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Button type="submit" sx={{ mt: 2, mb: 1 }}>
        SAVE
      </Button>
    </Form>
  );
};
