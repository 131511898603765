import { CreateFoodFab as CreateFoodFabComponent } from "@modules/Foods/components";
import { FC } from "react";
import { useEditFoodContext } from "@modules/Foods/contexts";

export interface CreateFoodFabProps {}

export const CreateFoodFab: FC<CreateFoodFabProps> = () => {
  const { createFood } = useEditFoodContext();

  return <CreateFoodFabComponent onClick={() => createFood()} />;
};
