import { Button, Dialog, Row, Text } from "@components";
import { useConfirm } from "@hooks";
import { createContext, useContext, useState, FC, ReactNode } from "react";

export type ConfirmData = {
  text?: string;
  okText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

interface ConfirmContextValue {
  isOpen: boolean;
  data: ConfirmData;
  setData: (value: ConfirmData) => void;
  resetData: () => void;
}

interface ConfirmProviderProps {
  children: ReactNode;
}

const ConfirmContext = createContext<ConfirmContextValue | undefined>(
  undefined
);

const useConfirmContextValue = () => {
  const [data, setData] = useState<ConfirmData>({});

  const resetData = () => {
    setData({});
  };

  const isOpen = Boolean(data.text);

  return {
    isOpen,
    data,
    setData,
    resetData,
  };
};

const ConfirmDialog: FC = () => {
  const { isOpen, text, okText, cancelText, onCancel, onConfirm } =
    useConfirm();

  return (
    <Dialog open={isOpen}>
      <Text>{text}</Text>
      <Row sx={{ justifyContent: 'flex-end', mt: 2 }}>
        <Button color="error" size="small" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button color="success" size="small" onClick={onConfirm} sx={{ ml: 1 }}>
          {okText}
        </Button>
      </Row>
    </Dialog>
  );
};

export const ConfirmProvider: FC<ConfirmProviderProps> = ({ children }) => {
  const value = useConfirmContextValue();
  return (
    <ConfirmContext.Provider value={value}>
      {children}
      <ConfirmDialog />
    </ConfirmContext.Provider>
  );
};

export const useConfirmContext = () => {
  const context = useContext(ConfirmContext);

  if (!context)
    throw new Error("ConfirmContext must be inside a ConfirmProvider");

  const { isOpen, data, setData, resetData } = context;
  const {
    text = "",
    okText = "OK",
    cancelText = "Cancel",
    onCancel,
    onConfirm,
  } = data;

  return {
    isOpen,
    text,
    okText,
    cancelText,
    onCancel,
    onConfirm,
    setData,
    resetData,
  };
};
