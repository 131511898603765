import { decodeBase64 } from "./base64"

export const isAccessTokenValid = (accessToken: string | undefined) => {
	try {
		if (typeof accessToken !== "string") throw new Error("invalid accessToken")
		const chunks = accessToken.split(".")
		if (chunks.length !== 3) throw new Error("invalid accessToken")
		const payload = JSON.parse(decodeBase64(chunks[1]))
		return typeof payload?.userId === "number"
	} catch (error) {
		return false
	}
}