import { List } from "@components/material";
import { Food } from "@modules/Foods/types";
import { LinearProgress } from "@mui/material";
import { FC } from "react";
import { FoodsListItem } from "./components";

export interface FoodsListProps {
  items: Food[];
  pending: boolean;
  onClickItem?: (food: Food) => void;
  onDelete?: (food: Food) => void;
  onEdit?: (food: Food) => void;
  onCreateImage?: (food: Food, image: string) => void;
  onDeleteImage?: (foodId: number, imageId: number) => void;
}

export const FoodsList: FC<FoodsListProps> = ({
  items,
  pending,
  onClickItem,
  onDelete,
  onEdit,
  onCreateImage,
  onDeleteImage,
}) => {
  return (
    <List>
      {pending ? (
        <LinearProgress />
      ) : (
        items.map((food) => {
          return (
            <FoodsListItem
              key={food.id}
              food={food}
              onClick={onClickItem}
              onDelete={onDelete}
              onEdit={onEdit}
              onCreateImage={onCreateImage}
              onDeleteImage={onDeleteImage}
            />
          );
        })
      )}
    </List>
  );
};
