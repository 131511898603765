import { Column } from "@components";
import { useLogContext } from "@contexts";
import { ErrorNavigate } from "@modules/Error/components/ErrorNavigate/ErrorNavigate";
import { useCreateCustomLog } from "@modules/Log/hooks/useCreateCustomLog/useCreateCustomLog";
import { FC } from "react";
import { CustomLogForm } from "../../components";

export interface CustomLogSectionProps {}

export const CustomLogSection: FC<CustomLogSectionProps> = () => {
  const { createCustomLog } = useCreateCustomLog(); 
  const { date, category } = useLogContext();
  if (date===undefined) {
    return <ErrorNavigate message={`Date is unknown`} />;
  }

  if (category===undefined) {
    return <ErrorNavigate message={`Category is unknown`} />;
  }

  return (
    <Column sx={{p: 1}}>
      <CustomLogForm
      onCreate={createCustomLog}
      defaultValues={{date, category, name:"", protein:0, carbs: 0, fats:0}}
      />
    </Column>
  );
};
