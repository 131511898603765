import { FoodsList as FoodsListComponent } from "@modules/Foods/components";
import { FC } from "react";
import {
  useDeleteFood,
  useGetFoods,
  useCreateFoodImage,
  useDeleteFoodImage,
} from "@modules/Foods/hooks";
import { useEditFoodContext } from "@modules/Foods/contexts";
import { Food } from "@modules/Foods/types";
import { useConfirm } from "@hooks";

export interface FoodsListProps {}

export const FoodsList: FC<FoodsListProps> = () => {
  const { updateFood } = useEditFoodContext();
  const { foods, isLoading } = useGetFoods();
  const { deleteFood } = useDeleteFood();
  const { createImage } = useCreateFoodImage();
  const { deleteImage } = useDeleteFoodImage();
  const { openConfirm } = useConfirm()

  const handleDeleteFood = (food: Food) => {
    openConfirm({
      text: `Do you really want to delete '${food.name}'?`,
      okText: 'Delete',
      onConfirm: () => {
        deleteFood(food)
      }
    })
  }

  const handleDeleteFoodImage = (foodId: number, imageId: number) => {
    openConfirm({
      text: `Do you really want to delete the image?`,
      okText: 'Delete',
      onConfirm: () => {
        deleteImage(foodId, imageId)
      }
    })
  }

  return (
    <FoodsListComponent
      items={foods}
      pending={isLoading}
      onDelete={handleDeleteFood}
      onEdit={updateFood}
      onCreateImage={createImage}
      onDeleteImage={handleDeleteFoodImage}
    />
  );
};
