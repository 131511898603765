import { ChangeEventHandler, FC, MouseEventHandler } from "react";
import {
  AvatarProps as MaterialAvatarProps,
  Input,
  ListItemAvatar as MaterialListItemAvatar,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useDialog } from "@hooks";
import { convertImage } from "@utils/base64";
import { Dialog, IconButton, Avatar, DialogProps } from "@components";

type Image = {
  id: number;
  src: string;
};

export interface ListItemAvatarProps extends MaterialAvatarProps {
  image: Image | null;
  onCreateImage?: (image: string) => void;
  onDeleteImage?: (imageId: number) => void;
}

export const ListItemAvatar: FC<ListItemAvatarProps> = ({
  id,
  image,
  onDeleteImage,
  onCreateImage,
}) => {
  const { isOpen, closeDialog, openDialog } = useDialog();

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    e.stopPropagation();
    const files = e.currentTarget.files;
    if (onCreateImage && files && files.length > 0) {
      const image = await convertImage(files[0]);
      onCreateImage(image);
    }
  };

  const handleClickLabel: MouseEventHandler<HTMLLabelElement> = (e) => {
    e.stopPropagation();
  };

  const src = image ? image.src : "";

  return src !== "" ? (
    <>
      <MaterialListItemAvatar>
        <Avatar image={image} onClick={openDialog} />
      </MaterialListItemAvatar>
      <PhotoDialog
        open={isOpen}
        image={image}
        onBackdropClick={closeDialog}
        onDeleteImage={onDeleteImage}
      />
    </>
  ) : onCreateImage ? (
    <label htmlFor={id} onClick={handleClickLabel}>
      <MaterialListItemAvatar>
        <Avatar/>
      </MaterialListItemAvatar>
      <Input
        id={id}
        sx={{ display: "none" }}
        type="file"
        onChange={handleChange}
      />
    </label>
  ) : (
    <MaterialListItemAvatar>
      <Avatar/>
    </MaterialListItemAvatar>
  );
};

interface PhotoDialogProps extends DialogProps {
  image: Image | null;
  onDeleteImage?: (imageId: number) => void;
}

const PhotoDialog: FC<PhotoDialogProps> = ({
  image,
  onDeleteImage,
  onBackdropClick,
  ...props
}) => {
  const handleDeleteImage: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (onDeleteImage && image) {
      onDeleteImage(image.id);
    }
  };

  const handleCloseDialog: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (onBackdropClick) {
      onBackdropClick(e);
    }
  };

  const src = image ? image.src : "";

  return (
    <Dialog onBackdropClick={handleCloseDialog} {...props}>
      {onDeleteImage && (
        <IconButton
          sx={{ position: "absolute", right: 8, top: 8 }}
          onClick={handleDeleteImage}
        >
          <Delete />
        </IconButton>
      )}
      <img
        src={src}
        alt="avatar dialog view"
        style={{ width: "100%", height: "auto" }}
      />
    </Dialog>
  );
};
