import { Box, BoxProps } from "@mui/material";
import { FC, forwardRef } from "react";

export interface ColumnProps extends BoxProps {}

export const Column: FC<ColumnProps> = forwardRef(
  ({ sx, children, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          ...sx,
        }}
        {...props}
      >
        {children}
      </Box>
    );
  }
);
