import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useQueryClient, useMutation } from "react-query";

export const useDeleteFoodImage = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<
    any,
    unknown,
    { imageId: number; foodId: number }
  >(
    (data: { imageId: number; foodId: number }) =>
      MyFitAppApi.deleteFoodImage({ accessToken, ...data }),
    {
      onSuccess: () => {
        showSuccess({ text: "image was deleted successfully" });
        queryClient.invalidateQueries("foods");
      },
      onError: () => {
        showError({ text: "image could not be deleted" });
      },
    }
  );

  return {
    ...mutation,
    deleteImage: (foodId: number, imageId: number) =>
      mutate({ foodId, imageId }),
  };
};
