import { CreateRecipeData, UpdateRecipeData } from "@apis/MyFitApp";
import { Dialog } from "@components";
import { Food } from "@modules/Foods/types";
import { RecipeFormData } from "@modules/Recipes/types";
import { FC } from "react";
import { RecipeForm } from "./components";

export interface RecipeDialogProps {
  open: boolean;
  onCreate?: (data: CreateRecipeData) => void;
  onUpdate?: (data: UpdateRecipeData) => void;
  onClose: () => void;
  recipe?: RecipeFormData | null;
  foods: Food[];
}

export const RecipeDialog: FC<RecipeDialogProps> = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  recipe,
  foods,
}) => {
  const handleCreate = (data: CreateRecipeData) => {
    onClose();
    if (onCreate) {
      onCreate(data);
    }
  };

  const handleUpdate = (data: UpdateRecipeData) => {
    onClose();
    if (onUpdate) {
      onUpdate(data);
    }
  };

  return (
    <Dialog open={open} onBackdropClick={onClose}>
      <RecipeForm
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        defaultValues={recipe}
        foods={foods}
      />
    </Dialog>
  );
};
