import { fetchApi } from "../utils";

export const getCurrentTargets = async ({
  accessToken,
  date,
}: {
  accessToken: string;
  date: string;
}) => {
  const { nutritionTarget } = await fetchApi("/targets/current", {
    accessToken,
    params: {
      date,
    },
  });
  return { nutritionTarget };
};