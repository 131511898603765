import { Dialog as MaterialDialog, DialogProps as MaterialDialogProps } from "@mui/material";
import { FC, ReactEventHandler } from "react";

export interface DialogProps extends MaterialDialogProps {
  onClose?: ReactEventHandler<{}>
}

export const Dialog : FC<DialogProps> = ({ open, children, PaperProps, ...props }) => {
  return (
    <MaterialDialog
      open={open}
      role="dialog"
      aria-hidden={!open}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          borderRadius: 5,
          p: 2,
          ...PaperProps?.sx,
        },
      }}
      {...props}
    >
      {children}
    </MaterialDialog>
  );
};
