import {
  ListItemButton as MaterialListItemButton,
  ListItemButtonProps as MaterialListItemButtonProps,
} from "@mui/material";
import { FC } from "react";

export interface ListItemButtonProps extends MaterialListItemButtonProps {
  isCard?: boolean;
}

export const ListItemButton: FC<ListItemButtonProps> = ({
  children,
  isCard = true,
  sx,
  ...props
}) => {
  const bgcolor = isCard ? "background.card" : null;
  return (
    <MaterialListItemButton
      sx={{
        bgcolor,
        borderRadius: 5,
        boxShadow: 6,
        mb: 1,
        ...sx,
      }}
      {...props}
    >
      {children}
    </MaterialListItemButton>
  );
};
