import { Text } from "@components";
import { FoodDiet, FOOD_DIET } from "@modules/Foods/types";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FC } from "react";

export interface FoodDietToggleButtonProps {
  value: string;
  onChange: (value: FoodDiet) => void;
}

export const FoodDietToggleButton: FC<FoodDietToggleButtonProps> = ({
  value,
  onChange,
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    diet: FoodDiet
  ) => {
    onChange(diet);
  };

  return (
    <>
      <Text sx={{ mb: 1 }}>Diet</Text>
      <ToggleButtonGroup
        size="small"
        onChange={handleChange}
        value={value}
        exclusive
        fullWidth
      >
        {Object.values(FOOD_DIET).map(({ id, label }) => (
          <ToggleButton value={id}>{label}</ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};
