import { ListItem } from "@components";
import { Food } from "@modules/Foods/types";
import { FC, MouseEventHandler } from "react";

export interface FoodsListItemProps {
  food: Food;
  onClick?: (food: Food) => void;
  onDelete?: (food: Food) => void;
  onEdit?: (food: Food) => void;
  onCreateImage?: (food: Food, image: string) => void;
  onDeleteImage?: (foodId: number, imageId: number) => void;
}

export const FoodsListItem: FC<FoodsListItemProps> = ({
  food,
  onClick,
  onDelete,
  onEdit,
  onCreateImage,
  onDeleteImage,
}) => {
  const handleClickItem: MouseEventHandler<HTMLDivElement> = () => {
    if (onClick) {
      onClick(food);
    }
  };

  const handleDeleteItem = () => {
    if (onDelete) {
      onDelete(food);
    }
  };

  const handleEditItem = () => {
    if (onEdit) {
      onEdit(food);
    }
  };

  const handleCreateImage = (image: string) => {
    if (onCreateImage) {
      onCreateImage(food, image);
    }
  };

  const handleDeleteImage = (imageId: number) => {
    if (onDeleteImage) {
      onDeleteImage(food.id, imageId);
    }
  };

  const image = food.images.length > 0 ? food.images[0] : null;

  return (
    <ListItem
      listItemAvatar={{
        image,
        onDeleteImage: handleDeleteImage,
        onCreateImage: handleCreateImage,
      }}
      listItemText={{
        primary: food.name,
        secondary: `${food.calories}kcal ${food.protein}p ${food.carbs}c ${food.fats}f`,
      }}
      onClick={handleClickItem}
      onEdit={handleEditItem}
      onDelete={handleDeleteItem}
    />
  );
};
