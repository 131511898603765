import { FC } from "react";
import {
  Tab as MaterialTab,
  TabProps as MaterialTabProps,
} from "@mui/material";

export interface TabProps extends MaterialTabProps {}

export const Tab: FC<TabProps> = (props) => {
  return <MaterialTab {...props} />;
};
