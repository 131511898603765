import { GoBackPage } from "@components";
import { CreateFoodFab, FoodsList } from "@modules/Foods";
import { FC } from "react";

const FoodsPage: FC = () => {
  return (
    <GoBackPage title="Foods">
      <FoodsList />
      <CreateFoodFab/>
    </GoBackPage>
  );
};

export default FoodsPage;
