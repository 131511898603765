import * as AuthApi from "./auth/auth.api";
import * as FoodsApi from "./foods/foods.api";
import * as RecipesApi from "./recipes/recipes.api";
import * as LogsApi from "./logs/logs.api";
import * as TargetsApi from "./targets/targets.api";

const MyFitAppApi = {
  ...AuthApi,
  ...FoodsApi,
  ...RecipesApi,
  ...LogsApi,
  ...TargetsApi,
};

export * from "./foods/foods.types";
export * from "./recipes/recipes.types";

export default MyFitAppApi;
