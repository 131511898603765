import { useCallback } from "react";
import { useAlertContext } from "@contexts";
import { AlertColor } from "@mui/material";

const DEFAULT_DURATION = 3000;

type AlertConfig = {
  duration?: number;
};

type ShowConcreteAlertConfig = {
  text: string;
  onClose?: () => void;
};

type ShowAlertConfig = ShowConcreteAlertConfig & {
  color: AlertColor;
};

const useAlert = (config?: AlertConfig) => {
  const duration = config?.duration || DEFAULT_DURATION;
  const { setData, data } = useAlertContext();

  const showAlert = useCallback(
    ({ text, color, onClose }: ShowAlertConfig) => {
      setData({
        text,
        color,
        duration,
        onClose,
      });
    },
    [duration, setData]
  );

  const close = () => {
    setData(null);
    if (data?.onClose) {
      data.onClose();
    }
  };

  const showSuccess = useCallback(
    ({ text, onClose }: ShowConcreteAlertConfig) =>
      showAlert({ text, onClose, color: "success" }),
    [showAlert]
  );
  const showError = useCallback(
    ({ text, onClose }: ShowConcreteAlertConfig) =>
      showAlert({ text, onClose, color: "error" }),
    [showAlert]
  );

  const visible = data !== null;

  return {
    showSuccess,
    showError,
    close,
    visible,
    text: data?.text,
    color: data?.color,
    duration: data?.duration,
  };
};

export default useAlert;
