import MyFitAppApi, { CreateFoodImageData } from "@apis/MyFitApp";
import { Food } from "@modules/Foods/types";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useQueryClient, useMutation } from "react-query";

export const useCreateFoodImage = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, CreateFoodImageData>(
    (data: { foodId: number; image: string }) =>
      MyFitAppApi.createFoodImage({ accessToken, data }),
    {
      onSuccess: () => {
        showSuccess({ text: "image was created successfully" });
        queryClient.invalidateQueries("foods");
      },
      onError: () => {
        showError({ text: "image could not be created" });
      },
    }
  );

  return {
    ...mutation,
    createImage: (food: Food, image: string) =>
      mutate({ foodId: food.id, image }),
  };
};
