import { FoodDialog } from "@modules/Foods/containers";
import { Food, FoodFormData } from "@modules/Foods/types";
import { createContext, FC, ReactNode, useContext, useState } from "react";

interface EditFoodContextValue {
  isOpen: boolean;
  food?: FoodFormData;
  createFood: (data?: { name: string }) => void;
  updateFood: (food: Food) => void;
  resetFood: () => void;
}

const EditFoodContext = createContext<EditFoodContextValue | undefined>(
  undefined
);

const useEditFoodValue = () => {
  const [food, setFood] = useState<FoodFormData | undefined>(undefined);

  const createFood = (data?: { name: string }) => {
    if (data) {
      setFood({
        name: data.name,
        protein: 0,
        carbs: 0,
        fats: 0,
        units: [],
        labels: [],
        diet: "ALL",
      });
    } else {
      setFood({
        name: "",
        protein: 0,
        carbs: 0,
        fats: 0,
        units: [],
        labels: [],
        diet: "ALL",
      });
    }
  };

  const updateFood = (food: Food) => {
    setFood(food);
  };

  const resetFood = () => {
    setFood(undefined);
  };

  const isOpen = food !== undefined;

  return {
    isOpen,
    food,
    createFood,
    updateFood,
    resetFood,
  };
};

interface EditFoodProviderProps {
  children: ReactNode;
}

export const EditFoodProvider: FC<EditFoodProviderProps> = ({ children }) => {
  const value = useEditFoodValue();

  return (
    <EditFoodContext.Provider value={value}>
      {children}
      <FoodDialog />
    </EditFoodContext.Provider>
  );
};

export const useEditFoodContext = () => {
  const context = useContext(EditFoodContext);

  if (!context)
    throw new Error("EditFoodContext must be inside a EditFoodProvider");

  return context;
};
