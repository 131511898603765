import { PrivacyProvider } from "@modules/Auth";
import {
  AccessTokenProvider,
  AlertProvider,
  LogProvider,
  ConfirmProvider,
} from "@contexts";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ROUTES from "./routes";
import { EditFoodProvider } from "@modules/Foods/contexts";
import { EditFoodLogProvider } from "@modules/Log/contexts";
import { EditRecipeProvider } from "@modules/Recipes/contexts";
import { EditRecipeLogProvider } from "@modules/Log/contexts/EditRecipeLogContext/EditRecipeLogContext";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AccessTokenProvider>
          <AlertProvider>
            <ConfirmProvider>
              <LogProvider>
                <EditFoodProvider>
                  <EditRecipeProvider>
                    <EditFoodLogProvider>
                      <EditRecipeLogProvider>
                        <Routes>
                          {ROUTES.public.map(({ path, page: Page }) => (
                            <Route key={path} path={path} element={<Page />} />
                          ))}
                          {ROUTES.private.map(({ path, page: Page }) => (
                            <Route
                              key={path}
                              path={path}
                              element={
                                <PrivacyProvider>
                                  <Page />
                                </PrivacyProvider>
                              }
                            />
                          ))}
                        </Routes>
                      </EditRecipeLogProvider>
                    </EditFoodLogProvider>
                  </EditRecipeProvider>
                </EditFoodProvider>
              </LogProvider>
            </ConfirmProvider>
          </AlertProvider>
        </AccessTokenProvider>
      </QueryClientProvider>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
