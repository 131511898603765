import MyFitAppApi from "@apis/MyFitApp";
import { CreateFoodLogData } from "@apis/MyFitApp/logs/logs.types";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useMutation, useQueryClient } from "react-query";

export const useCreateFoodLog = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, CreateFoodLogData>(
    (data: CreateFoodLogData) => MyFitAppApi.createFoodLog({ accessToken, data }),
    {
      onSuccess: ({ date }) => {
        showSuccess({ text: "food log was created successfully" });
        queryClient.invalidateQueries(['logs', date]);
      },
      onError: () => {
        showError({ text: "food log could not be created" });
      },
    }
  );

  return {
    ...mutation,
    createFoodLog: (data: CreateFoodLogData) => mutate(data),
  };
};
