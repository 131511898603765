import { DatePicker } from "@components";
import { useNutritionLogContext } from "@modules/Log/contexts/NutritionLogContext/NutritionLogContext";
import { FC } from "react";

export interface LogDatePickerProps {}

export const LogDatePicker: FC<LogDatePickerProps> = () => {
  const { date, increaseDate, decreaseDate } = useNutritionLogContext();
  return (
    <DatePicker
      date={date}
      onIncreaseDate={increaseDate}
      onDecreaseDate={decreaseDate}
    />
  );
};
