import { ListItem } from "@components";
import { Recipe } from "@modules/Recipes/types";
import { FC, MouseEventHandler } from "react";

export interface RecipesListItemProps {
  recipe: Recipe;
  onClick?: (recipe: Recipe) => void;
  onDelete?: (recipe: Recipe) => void;
  onEdit?: (recipe: Recipe) => void;
  onCreateImage?: (recipe: Recipe, image: string) => void;
  onDeleteImage?: (recipeId: number, imageId: number) => void;
}

export const RecipesListItem: FC<RecipesListItemProps> = ({
  recipe,
  onClick,
  onDelete,
  onEdit,
  onCreateImage,
  onDeleteImage,
}) => {
  const handleClickItem: MouseEventHandler<HTMLDivElement> = () => {
    if (onClick) {
      onClick(recipe);
    }
  };

  const handleDeleteItem = () => {
    if (onDelete) {
      onDelete(recipe);
    }
  };

  const handleEditItem = () => {
    if (onEdit) {
      onEdit(recipe);
    }
  };

  const handleCreateImage = (image: string) => {
    if (onCreateImage) {
      onCreateImage(recipe, image);
    }
  };

  const handleDeleteImage = (imageId: number) => {
    if (onDeleteImage) {
      onDeleteImage(recipe.id, imageId);
    }
  };

  const image = recipe.images.length > 0 ? recipe.images[0] : null;

  return (
    <ListItem
      listItemAvatar={{
        image,
        onDeleteImage: handleDeleteImage,
        onCreateImage: handleCreateImage,
      }}
      listItemText={{
        primary: recipe.name,
      }}
      onClick={handleClickItem}
      onEdit={handleEditItem}
      onDelete={handleDeleteItem}
    />
  );
};
