import { useLogContext } from "@contexts";
import {
  RecipeLogFormData,
} from "@modules/Log/types";
import { createContext, FC, ReactNode, useContext, useState } from "react";
import { Recipe } from "@modules/Recipes/types";
import { RecipeLogDialog } from "@modules/Log/containers";

interface EditRecipeLogContextValue {
  isOpen: boolean;
  recipeLog?: RecipeLogFormData;
  createRecipeLog: (recipe: Recipe) => void;
  resetRecipeLog: () => void;
}

interface EditRecipeLogProviderProps {
  children: ReactNode;
}

const EditRecipeLogContext = createContext<
  EditRecipeLogContextValue | undefined
>(undefined);

const useEditRecipeLogContextValue = () => {
  const { date, category } = useLogContext();
  const [recipeLog, setRecipeLog] = useState<RecipeLogFormData | undefined>(
    undefined
  );

  const createRecipeLog = (recipe: Recipe) => {
    if (!date) throw new Error("date undefined");
    if (!category) throw new Error("category undefined");
    setRecipeLog({
      amount: 1,
      category,
      date,
      timing: null,
      recipe: {
        id: recipe.id,
        name: recipe.name,
      }
    });
  };

  const resetRecipeLog = () => {
    setRecipeLog(undefined);
  };

  const isOpen = recipeLog !== undefined;

  return {
    isOpen,
    recipeLog,
    createRecipeLog,
    resetRecipeLog,
  };
};

export const EditRecipeLogProvider: FC<EditRecipeLogProviderProps> = ({
  children,
}) => {
  const value = useEditRecipeLogContextValue();

  return (
    <EditRecipeLogContext.Provider value={value}>
      {children}
      <RecipeLogDialog />
    </EditRecipeLogContext.Provider>
  );
};

export const useEditRecipeLogContext = () => {
  const context = useContext(EditRecipeLogContext);

  if (!context)
    throw new Error(
      "EditRecipeLogContext must be inside a EditRecipeLogProvider"
    );

  return context;
};
