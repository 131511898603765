import { FC } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { formatDate } from "../../utils/format";
import { Text, Row } from "..";

export interface DatePickerProps {
  date: Date | undefined;
  onIncreaseDate: () => void;
  onDecreaseDate: () => void;
}

export const DatePicker: FC<DatePickerProps> = ({
  date,
  onIncreaseDate,
  onDecreaseDate,
}) => {
  return (
    <Row sx={{ justifyContent: "space-between", p: 2 }}>
      <IconButton onClick={onDecreaseDate} aria-label="Last Day">
        <ChevronLeft />
      </IconButton>
      <Text role="heading" aria-label="Current Date">
        {formatDate(date, {
          weekday: "long",
        })}
      </Text>
      <IconButton onClick={onIncreaseDate} aria-label="Next Day">
        <ChevronRight />
      </IconButton>
    </Row>
  );
};
