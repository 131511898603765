import {
  CreateFoodLogData,
  UpdateFoodLogData,
} from "@apis/MyFitApp/logs/logs.types";
import { Button, Form, Row, TextField } from "@components";
import { FoodUnit } from "@modules/Foods/types";
import {
  FoodLogCategory,
  FoodLogFormData,
  FoodLogTiming,
  FOOD_LOG_CATEGORIES,
} from "@modules/Log/types";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FC, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

const TIMING_OPTIONS = [
  {
    label: "Pre Workout",
    value: "PRE_WORKOUT",
  },
  {
    label: "Post Workout",
    value: "POST_WORKOUT",
  },
];

export interface FoodLogFormProps {
  foodUnits: FoodUnit[];
  defaultValues?: FoodLogFormData;
  onCreate: (data: CreateFoodLogData) => void;
  onUpdate: (data: UpdateFoodLogData) => void;
}

export const FoodLogForm: FC<FoodLogFormProps> = ({
  defaultValues,
  foodUnits,
  onCreate,
  onUpdate,
}) => {
  const [isFavoritePage, setIsFavoritePage] = useState(
    defaultValues?.food?.favoriteLogs?.length !== undefined &&
      defaultValues?.food?.favoriteLogs?.length > 0
  );
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FoodLogFormData>({ defaultValues });

  const isNew = watch("id") === undefined;
  const amount = watch("amount");
  const unitId = watch("unit.id");
  const timing = watch("timing");
  const category = watch("category");
  const favoriteLogs = watch("food.favoriteLogs");
  const favoriteLogId = `${amount}x${unitId}`;

  const handleChangeUnit = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    unitId: number
  ) => {
    if (unitId) {
      setValue("unit.id", unitId);
    }
  };

  const handleChangeTiming = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    timing: FoodLogTiming
  ) => {
    setValue("timing", timing ? timing : null);
  };

  const handleChangeCategory = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    category: FoodLogCategory
  ) => {
    setValue("category", category);
  };

  const handleChangeFavLog = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => {
    const favoriteLog = favoriteLogs.find(
      (favoriteLog) => favoriteLog.id === id
    );
    if (favoriteLog) {
      setValue("unit.id", favoriteLog.unit.id);
      setValue("amount", favoriteLog.amount);
    }
  };

  const submit: SubmitHandler<FoodLogFormData> = (data) => {
    if (data.id !== undefined) {
      onUpdate({ ...data, id: data.id });
    } else {
      onCreate(data);
    }
  };

  console.log({ isNew})

  return (
    <Form onSubmit={handleSubmit(submit)}>
      {isFavoritePage ? (
        <>
          <ToggleButtonGroup
            size="small"
            onChange={handleChangeFavLog}
            value={favoriteLogId}
            sx={{ display: "flex", flexWrap: "wrap", mt: 2, mb: 1 }}
            exclusive
          >
            {favoriteLogs.map((favoriteLog) => (
              <ToggleButton
                key={favoriteLog.id}
                value={favoriteLog.id}
                sx={{ textTransform: "none", flex: 1 }}
              >
                {`${favoriteLog.amount} x ${favoriteLog.unit.name}`}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Row sx={{ justifyContent: "flex-end" }}>
            <Button
              variant="text"
              onClick={() => setIsFavoritePage(false)}
              sx={{ mt: 1 }}
            >
              Go to Custom
            </Button>
          </Row>
        </>
      ) : (
        <>
          <TextField
            label="Amount"
            type="number"
            inputProps={{
              step: 0.1,
            }}
            helperText={errors?.amount?.message}
            {...register("amount", {
              required: "Amount required",
              min: {
                value: 0.1,
                message: "Must be greater than 0",
              },
              valueAsNumber: true,
            })}
          />
          <ToggleButtonGroup
            size="small"
            onChange={handleChangeUnit}
            value={unitId}
            sx={{ display: "flex", flexWrap: "wrap", mt: 2, mb: 1 }}
            exclusive
          >
            {foodUnits.map((foodUnit) => (
              <ToggleButton
                key={foodUnit.id}
                value={foodUnit.id}
                sx={{ textTransform: "none", flex: 1 }}
              >
                {foodUnit.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Row sx={{ justifyContent: "flex-end" }}>
            <Button
              variant="text"
              onClick={() => setIsFavoritePage(true)}
              sx={{ mt: 1 }}
            >
              Go to Favorite
            </Button>
          </Row>
        </>
      )}
      {!isNew && (
        <ToggleButtonGroup
          size="small"
          onChange={handleChangeCategory}
          sx={{ mt: 2, mb: 1 }}
          value={category}
          orientation="vertical"
          exclusive
        >
          {Object.values(FOOD_LOG_CATEGORIES).map((option) => (
            <ToggleButton
              key={option.id}
              value={option.id}
              sx={{ textTransform: "none", flex: 1 }}
            >
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
      <ToggleButtonGroup
        size="small"
        onChange={handleChangeTiming}
        sx={{ mt: 2, mb: 1 }}
        value={timing}
        exclusive
      >
        {TIMING_OPTIONS.map((option) => (
          <ToggleButton
            key={option.value}
            value={option.value}
            sx={{ textTransform: "none", flex: 1 }}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Button type="submit" sx={{ mt: 2, mb: 1 }}>
        SAVE
      </Button>
    </Form>
  );
};
