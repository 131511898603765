import { useConfirm } from "@hooks";
import { useEditRecipeLogContext } from "@modules/Log/contexts/EditRecipeLogContext/EditRecipeLogContext";
import { CreateRecipeFab } from "@modules/Recipes";
import { useEditRecipeContext } from "@modules/Recipes/contexts";
import {
  useCreateRecipeImage,
  useDeleteRecipe,
  useGetRecipes,
} from "@modules/Recipes/hooks";
import { useDeleteRecipeImage } from "@modules/Recipes/hooks/useDeleteRecipeImage/useDeleteRecipeImage";
import { Recipe } from "@modules/Recipes/types";
import { FC } from "react";
import { RecipeLogSection as RecipeLogSectionComponent } from "../../components";

export interface RecipeLogSectionProps {}

export const RecipeLogSection: FC<RecipeLogSectionProps> = () => {
  const { recipes, isLoading } = useGetRecipes();
  const { deleteRecipe } = useDeleteRecipe();
  const { createImage } = useCreateRecipeImage();
  const { deleteImage } = useDeleteRecipeImage();
  const { openConfirm } = useConfirm();
  const { createRecipe: handleCreateRecipe, updateRecipe: handleUpdateRecipe } =
    useEditRecipeContext();
  const { createRecipeLog: handleCreateRecipeLog } = useEditRecipeLogContext();

  const handleDeleteRecipe = (recipe: Recipe) => {
    openConfirm({
      text: `Do you really want to delete '${recipe.name}'?`,
      okText: "Delete",
      onConfirm: () => {
        deleteRecipe(recipe);
      },
    });
  };

  return (
    <>
      <RecipeLogSectionComponent
        items={recipes}
        pending={isLoading}
        onEdit={handleUpdateRecipe}
        onDelete={handleDeleteRecipe}
        onCreateImage={createImage}
        onDeleteImage={deleteImage}
        //onCreateRecipeLog={createFoodLog}
        onClickItem={handleCreateRecipeLog}
        onCreateMissingFood={handleCreateRecipe}
      />
      <CreateRecipeFab />
    </>
  );
};
