import dateApi from "date-and-time";
import { useState } from "react";

const useDate = (defaultDate = new Date()) => {
  const [date, setDate] = useState(defaultDate);

  const increaseDate = () => {
    setDate(dateApi.addDays(date, 1))
  };

  const decreaseDate = () => {
    setDate(dateApi.addDays(date, -1))
  };

  return {
    date,
    increaseDate,
    decreaseDate,
    setDate,
  };
};

export default useDate;
