import MyFitAppApi, { UpdateFoodData } from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useQueryClient, useMutation } from "react-query";

export const useUpdateFood = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, UpdateFoodData>(
    (data: UpdateFoodData) => MyFitAppApi.updateFood({ accessToken, data }),
    {
      onSuccess: () => {
        showSuccess({ text: "food update successfully" });
        queryClient.invalidateQueries("foods");
      },
      onError: () => {
        showError({ text: "food could not updated" });
      },
    }
  );

  return {
    ...mutation,
    updateFood: (food: UpdateFoodData) => mutate(food),
  };
};
