import { Column, MacroChips, Row, Text, IconButton } from "@components";
import {
  FoodLog,
  FoodLogCategory,
  FoodLogCategoryItem,
  Log,
  RecipeLog,
  CustomLog,
} from "@modules/Log/types";
import { IngredientFormData } from "@modules/Recipes/types";
import {
  Add,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  MoreVert,
  Photo,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Chip,
  Collapse,
  Divider,
  LinearProgress,
  Menu,
  MenuItem,
  Zoom,
} from "@mui/material";
import { FC, MouseEventHandler, useState } from "react";

// const formatTiming = (timing: FoodLogTiming | undefined) => {
//   if (!timing) return "-";
//   switch (timing) {
//     case "POST_WORKOUT":
//       return "Post Workout";

//     case "PRE_WORKOUT":
//       return "Pre Workout";

//     default:
//       return "-";
//   }
// };

export interface LoggedNutritionSectionProps {
  pending: boolean;
  categoryLog: Log;
  category: FoodLogCategoryItem;
  onAddNutrition: (category: FoodLogCategory) => void;
  onDeleteFoodLog: (foodLog: FoodLog) => void;
  onDeleteRecipeLog: (data: { recipeId: number; category: string }) => void;
  onDeleteCustomLog: (customLog: CustomLog) => void;
  onUpdateFoodLog: (foodLog: FoodLog) => void;
  onCreateRecipe: (data: { ingredients: IngredientFormData[] }) => void;
}

export const LoggedNutritionSection: FC<LoggedNutritionSectionProps> = ({
  pending,
  categoryLog,
  category,
  onAddNutrition,
  onDeleteFoodLog,
  onDeleteRecipeLog,
  onDeleteCustomLog,
  onUpdateFoodLog,
  onCreateRecipe,
}) => {
  const { macros, foodLogs, recipeLogs, customLogs } = categoryLog;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleAddNutrition = () => {
    onAddNutrition(category.id);
  };

  const handleCreateRecipe = () => {
    if (foodLogs.length > 0) {
      onCreateRecipe({
        ingredients: foodLogs.map((foodLog) => ({
          food: foodLog.food,
          unit: foodLog.unit,
          amount: foodLog.amount,
        })),
      });
    }
  };

  const itemAmount = foodLogs.length + recipeLogs.length + customLogs.length;

  const hasItems = itemAmount > 0;

  return (
    <Column
      sx={{
        bgcolor: "background.paper",
        borderRadius: 5,
        boxShadow: 5,
        p: 1,
        m: 1,
      }}
    >
      <Row sx={{ justifyContent: "space-between" }}>
        <IconButton onClick={handleAddNutrition} color="primary">
          <Add />
        </IconButton>
        <Row
          component={Button}
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{
            flex: 1,
            justifyContent: hasItems ? "space-between" : "flex-start",
          }}
        >
          <Text>{category.label}</Text>
          {hasItems && <Chip label={itemAmount} color="primary" size="small" />}
        </Row>
        <LoggedNutritionSectionMenu onCreateRecipe={handleCreateRecipe} />
      </Row>
      {pending ? (
        <LinearProgress />
      ) : (
        hasItems && (
          <>
            <Collapse in={isExpanded}>
              <Column>
                <Divider sx={{ mt: 1, mb: 1 }} />
                {recipeLogs.map((recipeLog, i) => (
                  <LoggedRecipeItem
                    key={i}
                    recipeLog={recipeLog}
                    onDelete={onDeleteFoodLog}
                    onEdit={onUpdateFoodLog}
                    onDeleteRecipeLog={onDeleteRecipeLog}
                  />
                ))}
                {foodLogs.map((foodLog) => (
                  <LoggedFoodItem
                    key={foodLog.id}
                    foodLog={foodLog}
                    onDelete={onDeleteFoodLog}
                    onEdit={onUpdateFoodLog}
                  />
                ))}
                {customLogs.map((customLog) => (
                  <LoggedCustomLogItem
                    key={customLog.id}
                    customLog={customLog}
                    onDelete={onDeleteCustomLog}
                    onEdit={console.log}
                  />
                ))}
              </Column>
            </Collapse>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Row sx={{ justifyContent: "flex-end" }}>
              <MacroChips macros={macros} />
            </Row>
          </>
        )
      )}
    </Column>
  );
};

interface LoggedFoodItemProps {
  foodLog: FoodLog;
  onEdit?: (foodLog: FoodLog) => void;
  onDelete?: (foodLog: FoodLog) => void;
}

const LoggedFoodItem: FC<LoggedFoodItemProps> = ({
  foodLog,
  onEdit,
  onDelete,
}) => {
  const [state, setState] = useState<number>(0);

  const handleClick: (
    func: ((foodLog: FoodLog) => void) | undefined
  ) => MouseEventHandler<HTMLButtonElement> = (func) => (e) => {
    e.stopPropagation();
    if (func) {
      func(foodLog);
    }
  };

  const handleState = () => {
    setState((state + 1) % 3);
  };

  const imageSrc =
    foodLog.food.images.length > 0 ? foodLog.food.images[0].src : "";

  const name = foodLog.name ? foodLog.name : foodLog.food.name;

  return (
    <Column sx={{ position: "relative" }}>
      <Zoom in={state === 0}>
        <Row
          sx={{ height: 45, justifyContent: "space-between" }}
          onClick={handleState}
        >
          <Row sx={{ overflow: "hidden", flex: 1 }}>
            <Avatar sx={{ width: 24, height: 24 }} src={imageSrc}>
              <Photo />
            </Avatar>
            <Column sx={{ ml: 2, overflow: "hidden", flex: 1 }}>
              <Text sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {name}
              </Text>
              {foodLog.unit.amount && (
                <Row>
                  <Text variant="caption">
                    {foodLog.amount * foodLog.unit.amount}g
                  </Text>
                  <Text variant="caption" sx={{ ml: 1 }}>
                    ({foodLog.amount} x {foodLog.unit.name})
                  </Text>
                </Row>
              )}
            </Column>
          </Row>
        </Row>
      </Zoom>
      <Zoom in={state === 1}>
        <Row
          sx={{
            position: "absolute",
            height: 45,
            left: 0,
            right: 0,
            justifyContent: "space-between",
          }}
          onClick={handleState}
        >
          <Avatar sx={{ width: 24, height: 24 }} src={imageSrc}>
            <Photo />
          </Avatar>
          <Row>
            <IconButton onClick={handleClick(onEdit)}>
              <Edit />
            </IconButton>
            <IconButton onClick={handleClick(onDelete)}>
              <Delete />
            </IconButton>
          </Row>
        </Row>
      </Zoom>
      <Zoom in={state === 2}>
        <Row
          sx={{
            position: "absolute",
            height: 45,
            left: 0,
            right: 0,
            justifyContent: "space-between",
          }}
          onClick={handleState}
        >
          <Avatar sx={{ width: 24, height: 24 }} src={imageSrc}>
            <Photo />
          </Avatar>
          <MacroChips macros={foodLog.macros} />
        </Row>
      </Zoom>
    </Column>
  );
};

interface LoggedRecipeItemProps {
  recipeLog: RecipeLog;
  onEdit?: (foodLog: FoodLog) => void;
  onDelete?: (foodLog: FoodLog) => void;
  onDeleteRecipeLog: (data: { recipeId: number; category: string }) => void;
}

const LoggedRecipeItem: FC<LoggedRecipeItemProps> = ({
  recipeLog,
  onEdit,
  onDelete,
  onDeleteRecipeLog,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [state, setState] = useState<number>(0);

  const handleState = () => {
    setState((state + 1) % 2);
  };

  const handleDeleteRecipeLog = () => {
    onDeleteRecipeLog({
      recipeId: recipeLog.recipe.id,
      category: recipeLog.category,
    });
  };

  const imageSrc =
    recipeLog.recipe.images.length > 0 ? recipeLog.recipe.images[0].src : "";

  const name = recipeLog.name ? recipeLog.name : recipeLog.recipe.name;

  return (
    <Column>
      <Row
        sx={{
          flex: 1,
          justifyContent: "space-between",
          pl: 0,
        }}
      >
        <Row sx={{ position: "relative", flex: 1 }}>
          <Zoom in={state === 0}>
            <Row
              component={Button}
              onClick={handleState}
              sx={{
                textTransform: "none",
                justifyContent: "flex-start",
                flex: 1,
                position: "absolute",
                height: 45,
                p: 0,
                width: "100%",
              }}
            >
              <Avatar sx={{ width: 24, height: 24 }} src={imageSrc}>
                <Photo />
              </Avatar>
              <Text align="left" sx={{ ml: 2, flex: 1 }}>
                {name}
              </Text>
            </Row>
          </Zoom>
          <Zoom in={state === 1}>
            <Row
              sx={{
                justifyContent: "space-between",
                flex: 1,
                position: "absolute",
                height: 45,
                width: "100%",
              }}
            >
              <Row onClick={handleState} sx={{ flex: 1 }}>
                <Avatar sx={{ width: 24, height: 24 }} src={imageSrc}>
                  <Photo />
                </Avatar>
              </Row>
              <Row>
                <IconButton onClick={handleDeleteRecipeLog}>
                  <Delete />
                </IconButton>
              </Row>
            </Row>
          </Zoom>
        </Row>
        <IconButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        {/* {recipeLog.timing && (
          <Chip size="small" label={formatTiming(recipeLog.timing)} />
        )} */}
      </Row>
      {isExpanded && <Divider sx={{ mt: 1, mb: 1 }} />}
      <Collapse in={isExpanded}>
        <Column sx={{ ml: 2, position: "relative" }}>
          {recipeLog.foodLogs.map((foodLog) => (
            <LoggedFoodItem
              foodLog={foodLog}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </Column>
      </Collapse>
    </Column>
  );
};

interface LoggedCustomLogItemProps {
  customLog: CustomLog;
  onEdit?: (customLog: CustomLog) => void;
  onDelete?: (customLog: CustomLog) => void;
}

const LoggedCustomLogItem: FC<LoggedCustomLogItemProps> = ({
  customLog,
  onEdit,
  onDelete,
}) => {
  const [state, setState] = useState<number>(0);

  const handleClick: (
    func: ((customLog: CustomLog) => void) | undefined
  ) => MouseEventHandler<HTMLButtonElement> = (func) => (e) => {
    e.stopPropagation();
    if (func) {
      func(customLog);
    }
  };

  const handleState = () => {
    setState((state + 1) % 3);
  };

  return (
    <Column sx={{ position: "relative" }}>
      <Zoom in={state === 0}>
        <Row
          sx={{ height: 45, justifyContent: "space-between" }}
          onClick={handleState}
        >
          <Text sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {customLog.name}
          </Text>
        </Row>
      </Zoom>
      <Zoom in={state === 1}>
        <Row
          sx={{
            position: "absolute",
            height: 45,
            left: 0,
            right: 0,
            justifyContent: "space-between",
          }}
          onClick={handleState}
        >
          <Text sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {customLog.name}
          </Text>
          <Row>
            {/* <IconButton onClick={handleClick(onEdit)}>
              <Edit />
            </IconButton> */}
            <IconButton onClick={handleClick(onDelete)}>
              <Delete />
            </IconButton>
          </Row>
        </Row>
      </Zoom>
      <Zoom in={state === 2}>
        <Row
          sx={{
            position: "absolute",
            height: 45,
            left: 0,
            right: 0,
            justifyContent: "flex-end",
          }}
          onClick={handleState}
        >
          <MacroChips macros={customLog.macros} />
        </Row>
      </Zoom>
    </Column>
  );
};

interface LoggedNutritionSectionMenuProps {
  onCreateRecipe: () => void;
}

const LoggedNutritionSectionMenu: FC<LoggedNutritionSectionMenuProps> = ({
  onCreateRecipe,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (callback?: () => void) => () => {
    setAnchorEl(null);
    if (callback) {
      callback();
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose()}
      >
        <MenuItem onClick={handleClose(onCreateRecipe)}>Create Recipe</MenuItem>
      </Menu>
    </>
  );
};
