import { CreateRecipeFab as CreateRecipeFabComponent } from "@modules/Recipes/components";
import { useEditRecipeContext } from "@modules/Recipes/contexts";
import { FC } from "react";

export interface CreateRecipeFabProps {}

export const CreateRecipeFab: FC<CreateRecipeFabProps> = (props) => {
  const { createRecipe } = useEditRecipeContext();

  return <CreateRecipeFabComponent onClick={() => createRecipe()} />;
};
