import { Alert, AlertColor, Slide } from "@mui/material";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  FC,
  ReactNode,
} from "react";
import { useAlert } from "@hooks";

export type AlertData = {
  text: string;
  duration: number;
  color: AlertColor;
  onClose?: () => void;
} | null;

interface AlertContextValue {
  data: AlertData;
  setData: (value: AlertData) => void;
}

interface AlertProviderProps {
  children: ReactNode;
}

const AlertContext = createContext<AlertContextValue | undefined>(undefined);

const useAlertContextValue = () => {
  const [data, setData] = useState<AlertData>(null);

  return {
    data,
    setData,
  };
};

const Notification: FC = () => {
  const { visible, duration, color, text, close } = useAlert();

  useEffect(() => {
    if (visible) {
      const timeoutId = setTimeout(() => {
        close();
      }, duration);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [visible, duration, close]);

  return (
    <Slide in={visible} direction='up'>
      <Alert
        sx={{ position: "absolute", bottom: 20, left: 20, right: 20 }}
        variant="filled"
        severity={color}
        onClose={close}
      >
        {text}
      </Alert>
    </Slide>
  );
};

export const AlertProvider: FC<AlertProviderProps> = ({ children }) => {
  const value = useAlertContextValue();
  return (
    <AlertContext.Provider value={value}>
      {children}
      <Notification />
    </AlertContext.Provider>
  );
};

export const useAlertContext = () => {
  const context = useContext(AlertContext);

  if (!context) throw new Error("AlertContext must be inside a AlertProvider");

  return context;
};
