import { createContext, FC, ReactNode, useContext } from "react";
import { useStoredState } from "@hooks";

interface AccessTokenContextValue {
  accessToken: string;
  setAccessToken: (value: string) => void;
}

interface AccessTokenProviderProps {
  children: ReactNode;
}

const AccessTokenContext = createContext<AccessTokenContextValue | undefined>(
  undefined
);

const useAccessTokenContextValue = () => {
  const [accessToken, setAccessToken] = useStoredState<string>("accessToken");

  return {
    accessToken,
    setAccessToken,
  };
};

export const AccessTokenProvider: FC<AccessTokenProviderProps> = ({
  children,
}) => {
  const value = useAccessTokenContextValue();

  return (
    <AccessTokenContext.Provider value={value}>
      {children}
    </AccessTokenContext.Provider>
  );
};

export const useAccessTokenContext = () => {
  const context = useContext(AccessTokenContext);

  if (!context)
    throw new Error("AccessTokenContext must be inside a AccessTokenProvider");

  return context;
};
