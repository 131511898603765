import MyFitAppApi from "@apis/MyFitApp";
import { CreateCustomLogData} from "@apis/MyFitApp/logs/logs.types";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useMutation, useQueryClient } from "react-query";

export const useCreateCustomLog = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, CreateCustomLogData>(
    (data: CreateCustomLogData) => MyFitAppApi.createCustomLog({ accessToken, data }),
    {
      onSuccess: ({ date }) => {
        showSuccess({ text: "custom log was created successfully" });
        queryClient.invalidateQueries(['logs', date]);
      },
      onError: () => {
        showError({ text: "food log could not be created" });
      },
    }
  );

  return {
    ...mutation,
    createCustomLog: (data: CreateCustomLogData) => mutate(data),
  };
};
