import { fetchApi } from "../utils";
import { CreateFoodLogData, CreateRecipeLogData, UpdateFoodLogData, CreateCustomLogData } from "./logs.types";

// --- CREATE ------------

export const createFoodLog = async ({
  accessToken,
  data,
}: {
  accessToken: string;
  data: CreateFoodLogData;
}) => {
  const { foodLog } = await fetchApi(`/logs/foods`, {
    method: "POST",
    accessToken,
    body: data,
  });
  return foodLog;
};

export const createRecipeLog = async ({
  accessToken,
  data,
}: {
  accessToken: string;
  data: CreateRecipeLogData;
}) => {
  const { recipeLog } = await fetchApi(`/logs/recipes`, {
    method: "POST",
    accessToken,
    body: data,
  });
  return recipeLog;
};

export const createCustomLog = async ({
  accessToken,
  data,
}: {
  accessToken: string;
  data: CreateCustomLogData;
}) => {
  const { customLog } = await fetchApi(`/logs/custom`, {
    method: "POST",
    accessToken,
    body: data,
  });
  return customLog
};

// --- READ   ------------

export const getLogs = async ({
  accessToken,
  date,
}: {
  accessToken: string;
  date: string;
}) => {
  const { macros, logs } = await fetchApi(`/logs`, {
    accessToken,
    params: {
      date,
    },
  });
  return { macros, logs };
};

// --- UPDATE ------------

export const updateFoodLog = async ({
  accessToken,
  data,
}: {
  accessToken: string;
  data: UpdateFoodLogData;
}) => {
  const { foodLog } = await fetchApi(`/logs/${data.id}/foods`, {
    method: "PUT",
    accessToken,
    body: data,
  });
  return foodLog;
};

// --- DELETE ------------

export const deleteFoodLog = async ({
  accessToken,
  foodLogId,
}: {
  accessToken: string;
  foodLogId: number;
}) => {
  await fetchApi(`/logs/${foodLogId}/foods`, {
    method: "DELETE",
    accessToken,
  });
};

export const deleteRecipeLog = async ({
  accessToken,
  date,
  recipeId,
  category,
}: {
  accessToken: string;
  date: string;
  recipeId: number;
  category: string;
}) => {
  const { date: resDate } = await fetchApi(`/logs/recipes`, {
    method: "DELETE",
    accessToken,
    params: {
      date,
      recipeId,
      category,
    }
  });

  return { date: resDate }
};

export const deleteCustomLog = async ({
  accessToken,
  customLogId,
}: {
  accessToken: string;
  customLogId: number;
}) => {
  await fetchApi(`/logs/${customLogId}/custom`, {
    method: "DELETE",
    accessToken,
  });
};