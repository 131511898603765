import { useAccessTokenContext } from "@contexts";
import { isAccessTokenValid } from "@utils/auth";
import { FC, ReactNode, useMemo } from "react";
import { PrivacyProvider as PrivacyProviderComponent } from "@modules/Auth/components";

export interface PrivacyProviderProps {
  children: ReactNode;
}

export const PrivacyProvider: FC<PrivacyProviderProps> = ({ children }) => {
  const { accessToken } = useAccessTokenContext();

  const isValidAccessToken = useMemo(() => {
    return isAccessTokenValid(accessToken);
  }, [accessToken]);

  return (
    <PrivacyProviderComponent hasAccess={isValidAccessToken} redirect="/login">
      {children}
    </PrivacyProviderComponent>
  );
};
