import { FC } from "react";
import { FoodDialog as FoodDialogComponent } from "@modules/Foods/components";
import { useEditFoodContext } from "@modules/Foods/contexts";
import { useCreateFood, useUpdateFood } from "@modules/Foods/hooks";

export interface FoodDialogProps {}

export const FoodDialog: FC<FoodDialogProps> = () => {
  const { isOpen, food, resetFood } = useEditFoodContext();
  const { createFood } = useCreateFood();
  const { updateFood } = useUpdateFood();

  return (
    <FoodDialogComponent
      open={isOpen}
      onClose={resetFood}
      onCreate={createFood}
      onUpdate={updateFood}
      food={food}
    />
  );
};
