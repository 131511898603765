import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { Food } from "@modules/Foods/types";
import { useQueryClient, useMutation } from "react-query";

export const useDeleteFood = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, number>(
    (foodId: number) => MyFitAppApi.deleteFood({ accessToken, foodId }),
    {
      onSuccess: () => {
        showSuccess({ text: "food deleted successfully" });
        queryClient.invalidateQueries("foods");
      },
      onError: () => {
        showError({ text: "food could not deleted" });
      },
    }
  );

  return {
    ...mutation,
    deleteFood: (food: Food) => mutate(food.id),
  };
};
