import { useDate } from "@hooks";
import { Macros } from "@modules/Foods/types";
import { useGetLogs } from "@modules/Log/hooks";
import { CategoryLogs } from "@modules/Log/types";
import { useGetCurrentTargets } from "@modules/Targets/hooks";
import { NutritionTarget } from "@modules/Targets/types";
import { createContext, FC, ReactNode, useContext } from "react";

interface NutritionLogContextValue {
  date: Date;
  setDate: (date: Date) => void;
  increaseDate: () => void;
  decreaseDate: () => void;
  isLoading: boolean;
  logs: CategoryLogs;
  macros: Macros;
  nutritionTarget: NutritionTarget;
}

interface NutritionLogProviderProps {
  children: ReactNode;
}

const NutritionLogContext = createContext<NutritionLogContextValue | undefined>(
  undefined
);

const useNutritionLogContextValue = () => {
  const { date, increaseDate, decreaseDate, setDate } = useDate();

  const dateStr = date.toISOString().substring(0, 10)

  const { logs, macros, isLoading: isGetLogsLoading } = useGetLogs({ date: dateStr });
  const { nutritionTarget, isLoading: isGetNutritionTargetLoading } = useGetCurrentTargets({ date: dateStr });

  const isLoading = isGetLogsLoading || isGetNutritionTargetLoading

  return { date, increaseDate, decreaseDate, setDate, isLoading, logs, macros, nutritionTarget };
};

export const NutritionLogProvider: FC<NutritionLogProviderProps> = ({
  children,
}) => {
  const value = useNutritionLogContextValue();

  return (
    <NutritionLogContext.Provider value={value}>
      {children}
    </NutritionLogContext.Provider>
  );
};

export const useNutritionLogContext = () => {
  const context = useContext(NutritionLogContext);

  if (!context)
    throw new Error(
      "NutritionLogContext must be inside a NutritionLogProvider"
    );

  const { date, ...rest } = context;

  const dateStr = date.toISOString().substring(0, 10);

  return { date, dateStr, ...rest };
};
