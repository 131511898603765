import { useCallback, useState } from "react";

function useRequest(request: () => Promise<void>, dependencies: []) {
  const [pending, setPending] = useState(false);

  const handleRequest = useCallback(async () => {
    setPending(true);
    try {
      await request();
    } catch (error) {}
    setPending(false);
    // eslint-disable-next-line
  }, [...dependencies]);

  return [handleRequest, { pending }];
}

export default useRequest;
