import {
  Fab as MaterialFab,
  FabProps as MaterialFabProps,
} from "@mui/material";
import { FC } from "react";

export interface FabProps extends MaterialFabProps {}

export const Fab: FC<FabProps> = ({ children, sx, ...props }) => {
  return (
    <MaterialFab
      color="primary"
      sx={{ position: "absolute", bottom: 24, right: 24, ...sx }}
      {...props}
    >
      {children}
    </MaterialFab>
  );
};
