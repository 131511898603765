import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { Food } from "@modules/Foods/types";
import { useQuery } from "react-query";

export const useGetFoods = () => {
  const { accessToken } = useAccessTokenContext();
  const { data = [], ...query } = useQuery<Food[]>("foods", () =>
    MyFitAppApi.getFoods({ accessToken })
  );
  return { ...query, foods: data };
};
