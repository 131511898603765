const getApiUri = () => {
  switch (process.env.REACT_APP_ENV) {
    case "development":
    case "staging":
      return "https://staging.api.myfitapp.hosting163530.a2f21.netcup.net";
    case "beta":
    case "production":
      return "https://api.myfitapp.hosting163530.a2f21.netcup.net";
    default:
      throw new Error("APP_ENV not found");
  }
};

const URI = getApiUri();

type FetchConfig = {
  body?: string;
  headers: { [key: string]: string };
  method: "GET" | "POST" | "PUT" | "DELETE";
};

type FetchApiConfig = {
  accessToken?: string;
  signal?: null;
  body?: { [key: string]: number | string | object | boolean | null };
  params?: { [key: string]: number | string };
  headers?: { [key: string]: string };
  method?: "GET" | "POST" | "PUT" | "DELETE";
};

export async function fetchApi<T> (endpoint: string, config: FetchApiConfig) : Promise<T> {
  const {
    headers,
    method = "GET",
    params = {},
    accessToken,
    body,
  } = config;
  const options : FetchConfig = {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  const queryParams = Object.keys(params)
    .filter((key) => params[key])
    .map((key) => key + "=" + params[key])
    .join("&");
  if (accessToken) {
    options.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  const res = await fetch(
    `${URI}${endpoint}${queryParams ? "?" + queryParams : ""}`,
    options
  );
  if (res.status !== 200) throw new Error(res.statusText);
  return await res.json();
};
