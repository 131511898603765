import { CreateRecipeData, UpdateRecipeData } from "@apis/MyFitApp";
import { Button, Form, TextField } from "@components";
import { Food } from "@modules/Foods/types";
import { RecipeFormData } from "@modules/Recipes/types";
import { FC } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { IngredientEditList } from "../IngredientEditList/IngredientEditList";

export interface RecipeFormProps {
  onCreate?: (food: CreateRecipeData) => void;
  onUpdate?: (food: UpdateRecipeData) => void;
  defaultValues?: RecipeFormData | null;
  foods: Food[];
}

export const RecipeForm: FC<RecipeFormProps> = ({
  onCreate,
  onUpdate,
  defaultValues,
  foods,
}) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<RecipeFormData>({
    defaultValues: defaultValues ? defaultValues : {},
  });
  const {
    fields: ingredients,
    append: addIngredient,
    remove: removeIngredient,
  } = useFieldArray({
    control,
    name: "ingredients",
  });

  const transformSubmitData: SubmitHandler<RecipeFormData> = async (recipe) => {
    if (recipe.id !== undefined) {
      if (onUpdate) {
        onUpdate({
          ...recipe,
          id: recipe.id,
        });
      }
    } else {
      if (onCreate) {
        onCreate(recipe);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(transformSubmitData)}>
      <TextField
        label="Name"
        helperText={errors?.name?.message}
        {...register("name", { required: "Name required" })}
      />
      <TextField
        label="Description"
        multiline={true}
        rows={2}
        helperText={errors?.description?.message}
        {...register("description")}
      />
      <IngredientEditList
        foods={foods}
        ingredients={ingredients}
        onAdd={addIngredient}
        onRemove={removeIngredient}
        register={register}
        control={control}
      />
      <Button type="submit" sx={{ mt: 2 }}>
        SAVE
      </Button>
    </Form>
  );
};
