import { To, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { Text, Row, HeaderPage, HeaderPageProps } from "@components";
import { FC } from "react";

export interface GoBackPageProps extends HeaderPageProps {
  title?: string;
  path?: string;
}

export const GoBackPage: FC<GoBackPageProps> = ({
  title,
  children,
  left,
  right,
  path,
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate((path ? path : -1) as To);
  };

  return (
    <HeaderPage
      left={
        <Row>
          <IconButton aria-label="Go Back" onClick={handleGoBack}>
            <ChevronLeft />
          </IconButton>
          {left}
          {title && <Text sx={{ ml: 1 }}>{title}</Text>}
        </Row>
      }
      right={right}
    >
      {children}
    </HeaderPage>
  );
};
