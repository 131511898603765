import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MaterialSelect,
  SelectProps as MaterialSelectProps,
} from "@mui/material";
import { ReactNode } from "react";
import {
  Controller,
  Control,
  FieldValues,
  RegisterOptions,
  FieldPath,
} from "react-hook-form";

export interface SelectProps<
  TFieldValues,
  TName extends FieldPath<TFieldValues>
> {
  id: string;
  label?: string;
  selectProps?: MaterialSelectProps;
  helperText?: string | undefined;
  children: ReactNode;
  name: TName;
  control: Control<TFieldValues, any>;
  rules: Omit<
    RegisterOptions<TFieldValues, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

export const Select = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  id,
  label,
  name,
  control,
  helperText,
  rules,
  children,
}: SelectProps<TFieldValues, TName>) => {
  return (
    <FormControl variant="standard" margin="none">
      {label && <InputLabel id={id}>{label}</InputLabel>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, ...field } }) => {
          return (
            <MaterialSelect
              labelId={id}
              error={typeof helperText === "string"}
              aria-label={label}
              value={value ? value : ""}
              {...field}
            >
              {children}
            </MaterialSelect>
          );
        }}
      />
      {helperText && (
        <FormHelperText sx={{ color: "error.main" }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
