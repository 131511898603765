import {
  List as MaterialList,
  ListProps as MaterialListProps,
} from "@mui/material";
import { FC } from "react";

export interface ListProps extends MaterialListProps {}

export const List: FC<ListProps> = ({ children, ...props }) => {
  return <MaterialList {...props}>{children}</MaterialList>;
};
