import { IconButton } from "@components/material";
import { Close, Search } from "@mui/icons-material";
import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";
import { ChangeEventHandler, FC } from "react";

export interface SearchFieldProps extends OutlinedInputProps {
  onSearch: (searchValue: string) => void;
  onClear: () => void;
}

export const SearchField: FC<SearchFieldProps> = ({
  onSearch,
  onClear,
  value,
  ...props
}) => {
  const handleChangeSearchField: ChangeEventHandler<HTMLInputElement> = (e) => {
    onSearch(e.currentTarget.value);
  };

  return (
    <OutlinedInput
      sx={{ mt: 1, mr: 1, ml: 1 }}
      onChange={handleChangeSearchField}
      value={value}
      endAdornment={
        <InputAdornment position="end">
          {value === '' ? (
            <Search />
          ) : (
            <IconButton sx={{ p: 0 }} onClick={onClear}>
              <Close />
            </IconButton>
          )}
        </InputAdornment>
      }
      {...props}
    />
  );
};
