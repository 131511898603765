import { Tab, Tabs } from "@components";
import { FC, SyntheticEvent } from "react";

export type LogCategoryTabItem = {
  label: string;
  value: string;
};

export interface LogCategoryTabsProps {
  tabs: LogCategoryTabItem[];
  currentTab: string;
  onChange: (tab: string) => void;
}

export const LogCategoryTabs: FC<LogCategoryTabsProps> = ({
  currentTab,
  tabs,
  onChange,
}) => {
  const handleChange = (e: SyntheticEvent<Element, Event>, tab: string) => {
    onChange(tab);
  };

  return (
    <Tabs value={currentTab} onChange={handleChange}>
      {tabs.map((tab) => (
        <Tab key={tab.value} label={tab.label} value={tab.value} sx={{ flex:1 }}/>
      ))}
    </Tabs>
  );
};
