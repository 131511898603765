import { CircularProgress, CircularProgressProps } from "@mui/material";
import { FC } from "react";
import { Button, ButtonProps, Row } from "@components";

export interface PendingButtonProps extends ButtonProps {
  pending: boolean;
  progressProps: CircularProgressProps;
}

export const PendingButton: FC<PendingButtonProps> = ({
  pending = false,
  progressProps,
  children,
  ...props
}) => {
  return (
    <Row sx={{ justifyContent: "center", mt: 2, mb: 1 }}>
      {pending ? (
        <CircularProgress {...progressProps} />
      ) : (
        <Button {...props}>{children}</Button>
      )}
    </Row>
  );
};