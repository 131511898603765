import MyFitAppApi, { CreateRecipeData } from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useQueryClient, useMutation } from "react-query";

export const useCreateRecipe = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, CreateRecipeData>(
    (data: CreateRecipeData) => MyFitAppApi.createRecipe({ accessToken, data }),
    {
      onSuccess: () => {
        showSuccess({ text: "recipe was created successfully" });
        queryClient.invalidateQueries("recipes");
      },
      onError: () => {
        showError({ text: "recipe could not be created" });
      },
    }
  );

  return {
    ...mutation,
    createRecipe: (data: CreateRecipeData) => mutate(data),
  };
};
