import { FC, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

export interface PrivacyProviderProps {
  hasAccess: boolean;
  redirect: string;
  children: ReactNode;
}

export const PrivacyProvider: FC<PrivacyProviderProps> = ({
  hasAccess,
  redirect,
  children,
}) => {
  const location = useLocation();

  return hasAccess ? (
    <>{children}</>
  ) : (
    <Navigate to={redirect} state={{ from: location }} replace={true} />
  );
};
