import { Column, Row, Text } from "@components";
import { useLogContext } from "@contexts";
import { Macros } from "@modules/Foods/types";
import { LoggedNutritionSection } from "@modules/Log/components/LoggedNutritionSection/LoggedNutritionSection";
import { useEditFoodLogContext } from "@modules/Log/contexts";
import { useNutritionLogContext } from "@modules/Log/contexts/NutritionLogContext/NutritionLogContext";
import { useDeleteCustomLog } from "@modules/Log/hooks";
import { useDeleteFoodLog } from "@modules/Log/hooks/useDeleteFoodLog/useDeleteFoodLog";
import { useDeleteRecipeLog } from "@modules/Log/hooks/useDeleteRecipeLog/useDeleteRecipeLog";
import { FoodLogCategory, FOOD_LOG_CATEGORIES } from "@modules/Log/types";
import { useEditRecipeContext } from "@modules/Recipes/contexts";
import { CircularProgress } from "@mui/material";
import { formatDiffNumber, formatNumber } from "@utils/format";
import { FC, useMemo } from "react";

export interface LoggedNutritionProps {
  onAddNutrition: (category: FoodLogCategory) => void;
}

export const LoggedNutrition: FC<LoggedNutritionProps> = ({
  onAddNutrition,
}) => {
  const { date, dateStr, nutritionTarget, macros, logs, isLoading } =
    useNutritionLogContext();
  const { setLogData } = useLogContext();
  const { deleteFoodLog } = useDeleteFoodLog({ date: dateStr });
  const { deleteRecipeLog } = useDeleteRecipeLog({ date: dateStr });
  const { deleteCustomLog } = useDeleteCustomLog({ date: dateStr });
  const { updateFoodLog } = useEditFoodLogContext();
  const { createRecipe } = useEditRecipeContext();

  const handleAddNutrition = (category: FoodLogCategory) => {
    setLogData({
      date: date.toISOString().substring(0, 10),
      category,
    });
    onAddNutrition(category);
  };

  return (
    <Column>
      <LoggedMacros
        currentMacros={macros}
        targetMacros={nutritionTarget.macros}
      />
      <Column sx={{ overflowY: "scroll" }}>
        {Object.values(FOOD_LOG_CATEGORIES).map((category) => {
          const categoryLog = logs[category.id]
            ? logs[category.id]
            : {
                macros: {
                  calories: 0,
                  protein: 0,
                  carbs: 0,
                  fats: 0,
                },
                foodLogs: [],
                recipeLogs: [],
                customLogs: [],
              };
          return (
            <LoggedNutritionSection
              key={category.id}
              pending={isLoading}
              categoryLog={categoryLog}
              category={category}
              onAddNutrition={handleAddNutrition}
              onDeleteFoodLog={deleteFoodLog}
              onUpdateFoodLog={updateFoodLog}
              onDeleteRecipeLog={deleteRecipeLog}
              onDeleteCustomLog={deleteCustomLog}
              onCreateRecipe={createRecipe}
            />
          );
        })}
      </Column>
    </Column>
  );
};



interface LoggedMacrosProps {
  currentMacros: Macros;
  targetMacros: Macros;
}

const LoggedMacros: FC<LoggedMacrosProps> = ({
  currentMacros,
  targetMacros,
}) => {
  const diffMacros = useMemo(() => {
    return {
      calories: targetMacros.calories - currentMacros.calories,
      protein: targetMacros.protein - currentMacros.protein,
      carbs: targetMacros.carbs - currentMacros.carbs,
      fats: targetMacros.fats - currentMacros.fats,
    };
  }, [currentMacros, targetMacros]);

  const progress = useMemo(() => {
    return {
      calories: (currentMacros.calories / targetMacros.calories) * 100,
      protein: (currentMacros.protein / targetMacros.protein) * 100,
      carbs: (currentMacros.carbs / targetMacros.carbs) * 100,
      fats: (currentMacros.fats / targetMacros.fats) * 100,
    };
  }, [currentMacros, targetMacros]);

  return (
    <Row sx={{ justifyContent: "center" }}>
      <MacroProgressCard
        label="Calories"
        diff={diffMacros.calories}
        progress={progress.calories}
        value={currentMacros.calories}
      />
      <MacroProgressCard
        label="Protein"
        diff={diffMacros.protein}
        progress={progress.protein}
        value={currentMacros.protein}
      />
      <MacroProgressCard
        label="Carbs"
        diff={diffMacros.carbs}
        progress={progress.carbs}
        value={currentMacros.carbs}
      />
      <MacroProgressCard
        label="Fats"
        diff={diffMacros.fats}
        progress={progress.fats}
        value={currentMacros.fats}
      />
    </Row>
  );
};

interface MacroProgressCardProps {
  label: string;
  value: number;
  progress: number;
  diff: number;
  size?: number;
}

const MacroProgressCard: FC<MacroProgressCardProps> = ({
  label,
  value,
  diff,
  progress,
  size = 40,
}) => {
  return (
    <Column sx={{ alignItems: "center", m: 0.5 }}>
      <Column
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: size + 32,
          height: size + 32,
          border: "6px solid #ddd",
          borderRadius: "50%",
        }}
      >
        <CircularProgress
          size={size + 32}
          sx={{ position: "absolute" }}
          variant="determinate"
          color={diff >= 0 ? "success" : "error"}
          value={progress < 100 ? progress : 100}
        ></CircularProgress>
        <Column sx={{ alignItems: "center", width: size, height: size }}>
          <Text>{formatNumber(value)}</Text>
          <Text variant="caption" sx={{ color: diff >= 0 ? "success.dark" : "error.dark" }}>
            {formatDiffNumber(diff)}
          </Text>
        </Column>
      </Column>
      <Text variant="caption">{label}</Text>
    </Column>
  );
};
