import { FC } from "react";
import LoginPage from "@pages/LoginPage/LoginPage";
import StartPage from "@pages/StartPage/StartPage";
import FoodsPage from "@pages/FoodsPage/FoodsPage";
import RecipesPage from "@pages/RecipesPage/RecipesPage";
import LogPage from "@pages/LogPage/LogPage";
import ErrorPage from "@pages/ErrorPage/ErrorPage";

type Page = {
  path: string;
  page: FC;
};

const ROUTES: { public: Page[]; private: Page[] } = {
  public: [
    {
      path: "/login",
      page: LoginPage,
    },
    {
      path: "/error",
      page: ErrorPage,
    },
  ],
  private: [
    {
      path: "/",
      page: StartPage,
    },
    {
      path: "/foods",
      page: FoodsPage,
    },
    {
      path: "/recipes",
      page: RecipesPage,
    },
    {
      path: "/log/:logType",
      page: LogPage,
    },
  ],
};

export default ROUTES;
