import { fetchApi } from "../utils";
import {
  CreateFoodData,
  CreateFoodImageData,
  UpdateFoodData,
} from "./foods.types";

// --- CREATE ------------

export const createFood = async ({
  accessToken,
  data,
}: {
  accessToken: string;
  data: CreateFoodData;
}) => {
  const { food } = await fetchApi(`/foods`, {
    method: "POST",
    accessToken,
    body: data,
  });
  return food;
};

export const createFoodImage = async ({
  accessToken,
  data,
}: {
  accessToken: string;
  data: CreateFoodImageData;
}) => {
  const { image } = await fetchApi(`/foods/${data.foodId}/images`, {
    method: "POST",
    accessToken,
    body: {
      image: data.image,
    },
  });
  return image;
};

// --- READ   ------------

export const getFoods = async ({ accessToken }: { accessToken: string }) => {
  const { foods } = await fetchApi(`/foods`, {
    accessToken,
  });
  return foods;
};

// --- UPDATE ------------

export const updateFood = async ({
  accessToken,
  data,
}: {
  accessToken: string;
  data: UpdateFoodData;
}) => {
  const { food } = await fetchApi(`/foods/${data.id}`, {
    method: "PUT",
    accessToken,
    body: data,
  });
  return food;
};

// --- DELETE ------------

export const deleteFood = async ({
  accessToken,
  foodId,
}: {
  accessToken: string;
  foodId: number;
}) => {
  await fetchApi(`/foods/${foodId}`, {
    method: "DELETE",
    accessToken,
  });
};

export const deleteFoodImage = async ({
  accessToken,
  foodId,
  imageId,
}: {
  accessToken: string;
  foodId: number;
  imageId: number;
}) => {
  await fetchApi(`/foods/${foodId}/images/${imageId}`, {
    method: "DELETE",
    accessToken,
  });
};
