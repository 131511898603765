import { useConfirm } from "@hooks";
import { CreateFoodFab } from "@modules/Foods";
import { useEditFoodContext } from "@modules/Foods/contexts";
import {
  useCreateFoodImage,
  useDeleteFood,
  useDeleteFoodImage,
  useGetFoods,
  useUpdateFood,
} from "@modules/Foods/hooks";
import { Food } from "@modules/Foods/types";
import { useEditFoodLogContext } from "@modules/Log/contexts";
import { useCreateFoodLog } from "@modules/Log/hooks/useCreateFoodLog/useCreateFoodLog";
import { FC } from "react";
import { FoodLogSection as FoodLogSectionComponent } from "../../components";

export interface FoodLogSectionProps {}

export const FoodLogSection: FC<FoodLogSectionProps> = () => {
  const { foods, isLoading } = useGetFoods();
  const { updateFood } = useUpdateFood();
  const { deleteFood } = useDeleteFood();
  const { createImage } = useCreateFoodImage();
  const { deleteImage } = useDeleteFoodImage();
  const { createFoodLog } = useCreateFoodLog();
  const { openConfirm } = useConfirm();
  const { updateFood: handleUpdateFood, createFood: handleCreateFood } = useEditFoodContext();
  const { createFoodLog: handleCreateFoodLog } = useEditFoodLogContext();

  const handleDeleteFood = (food: Food) => {
    openConfirm({
      text: `Do you really want to delete '${food.name}'?`,
      okText: "Delete",
      onConfirm: () => {
        deleteFood(food);
      },
    });
  };

  return (
    <>
      <FoodLogSectionComponent
        items={foods}
        pending={isLoading}
        onUpdate={updateFood}
        onDelete={handleDeleteFood}
        onCreateImage={createImage}
        onDeleteImage={deleteImage}
        onCreateFoodLog={createFoodLog}
        onEdit={handleUpdateFood}
        onClickItem={handleCreateFoodLog}
        onCreateMissingFood={handleCreateFood}
      />
      <CreateFoodFab />
    </>
  );
};
