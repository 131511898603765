import { CreateFoodLogData, UpdateFoodLogData } from "@apis/MyFitApp/logs/logs.types";
import { Dialog } from "@components";
import { FoodUnit } from "@modules/Foods/types";
import { FoodLogFormData } from "@modules/Log/types";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { FoodLogForm } from "../FoodLogForm/FoodLogForm";

export interface FoodLogDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onCreate: (data: CreateFoodLogData) => void;
  onUpdate: (data: UpdateFoodLogData) => void;
  foodLog?: FoodLogFormData;
  foodUnits: FoodUnit[];
}

export const FoodLogDialog: FC<FoodLogDialogProps> = ({
  open,
  title,
  onClose,
  onCreate,
  onUpdate,
  foodLog,
  foodUnits = [],
}) => {
  const handleCreate = (data: CreateFoodLogData) => {
    onClose();
    onCreate(data);
  };

  const handleUpdate = (data: UpdateFoodLogData) => {
    onClose();
    onUpdate(data);
  };

  return (
    <Dialog open={open} onBackdropClick={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <FoodLogForm
        foodUnits={foodUnits}
        defaultValues={foodLog}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
      />
    </Dialog>
  );
};
