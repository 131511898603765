import { FC, MouseEventHandler } from "react";
import {
  Avatar as MaterialAvatar,
  AvatarProps as MaterialAvatarProps,
} from "@mui/material";
import { Photo } from "@mui/icons-material";

type Image = {
  id: number;
  src: string;
};

export interface AvatarProps extends MaterialAvatarProps {
  image?: Image | null;
}

export const Avatar: FC<AvatarProps> = ({ image, onClick }) => {

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if(onClick) {
      onClick(e)
    }
  };

  const src = image ? image.src : "";

  return (
    <MaterialAvatar src={src} onClick={handleClick}>
      <Photo />
    </MaterialAvatar>
  );
};
