import { FC } from "react";
import { RecipeDialog as RecipeDialogComponent } from "@modules/Recipes/components";
import { useEditRecipeContext } from "@modules/Recipes/contexts";
import { useCreateRecipe, useUpdateRecipe } from "@modules/Recipes/hooks";
import { useGetFoods } from "@modules/Foods/hooks";

export interface RecipeDialogProps {}

export const RecipeDialog: FC<RecipeDialogProps> = () => {
  const { isOpen, recipe, resetRecipe } = useEditRecipeContext();
  const { createRecipe } = useCreateRecipe();
  const { updateRecipe } = useUpdateRecipe();
  const { foods } = useGetFoods();

  return (
    <RecipeDialogComponent
      open={isOpen}
      onCreate={createRecipe}
      onUpdate={updateRecipe}
      onClose={resetRecipe}
      recipe={recipe}
      foods={foods}
    />
  );
};
