import { Food } from "@modules/Foods/types";
import { FoodLogDialog } from "@modules/Log";
import { useLogContext } from "@contexts";
import { FoodLog, FoodLogFormData } from "@modules/Log/types";
import { createContext, FC, ReactNode, useContext, useState } from "react";

interface EditFoodLogContextValue {
  isOpen: boolean;
  foodLog?: FoodLogFormData;
  createFoodLog: (food: Food) => void;
  updateFoodLog: (foodLog: FoodLog) => void;
  resetFoodLog: () => void;
}

interface EditFoodLogProviderProps {
  children: ReactNode;
}

const EditFoodLogContext = createContext<EditFoodLogContextValue | undefined>(
  undefined
);

const useEditFoodLogContextValue = () => {
  const { date, category } = useLogContext();
  const [foodLog, setFoodLog] = useState<FoodLogFormData | undefined>(
    undefined
  );

  const createFoodLog = (food: Food) => {
    if (!date) throw new Error("date undefined");
    if (!category) throw new Error("category undefined");
    setFoodLog({
      amount: 1,
      category,
      date,
      food: {
        id: food.id,
        name: food.name,
        units: food.units,
        favoriteLogs: food.favoriteLogs,
      },
      unit: {
        id: 1,
      },
      timing: null,
    });
  };

  const updateFoodLog = (foodLog: FoodLog) => {
    setFoodLog({
      id: foodLog.id,
      amount: foodLog.amount,
      category: foodLog.category,
      date: foodLog.date,
      timing: foodLog.timing,
      food: {
        id: foodLog.food.id,
        name: foodLog.food.name,
        units: foodLog.food.units,
        favoriteLogs: foodLog.food.favoriteLogs,
      },
      unit: {
        id: foodLog.unit.id,
      },
    });
  };

  const resetFoodLog = () => {
    setFoodLog(undefined);
  };

  const isOpen = foodLog !== undefined;

  return {
    isOpen,
    foodLog,
    createFoodLog,
    updateFoodLog,
    resetFoodLog,
  };
};

export const EditFoodLogProvider: FC<EditFoodLogProviderProps> = ({
  children,
}) => {
  const value = useEditFoodLogContextValue();

  return (
    <EditFoodLogContext.Provider value={value}>
      {children}
      <FoodLogDialog />
    </EditFoodLogContext.Provider>
  );
};

export const useEditFoodLogContext = () => {
  const context = useContext(EditFoodLogContext);

  if (!context)
    throw new Error("EditFoodLogContext must be inside a EditFoodLogProvider");

  return context;
};
