
export type FoodDiet = "ALL" | "VEGETARIAN" | "VEGAN"

export const FOOD_DIET = {
    ALL: {
        id: "ALL",
        label: "All"
    },
    VEGETARIAN: {
        id: "VEGETARIAN",
        label: "Vegetarian"
    },
    VEGAN: {
        id: "VEGAN",
        label: "Vegan"
    },
}