import { Page, PageProps } from "@components";
import { FC, ReactNode } from "react";
import { Row } from "..";

export interface HeaderPageProps {
  left?: JSX.Element;
  right?: JSX.Element;
  children?: ReactNode;
  pageProps?: PageProps;
}

export const HeaderPage: FC<HeaderPageProps> = ({
  left,
  right,
  children,
  pageProps,
}) => {
  return (
    <Page {...pageProps}>
      <Row sx={{ p: 2, justifyContent: "space-between" }}>
        {left}
        {right}
      </Row>
      {children}
    </Page>
  );
};