import { Page } from "@components";
import { LoggedNutrition, LogDatePicker } from "@modules/Log";
import { NutritionLogProvider } from "@modules/Log/contexts";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const StartPage: FC = () => {
  const navigate = useNavigate();

  const handleAddNutrition = () => {
    navigate("/log/recipes");
  };

  return (
    <NutritionLogProvider>
      <Page>
        <LogDatePicker />
        <LoggedNutrition onAddNutrition={handleAddNutrition} />
      </Page>
    </NutritionLogProvider>
  );
};

export default StartPage;
