import MyFitAppApi from "@apis/MyFitApp";
import { CreateRecipeLogData } from "@apis/MyFitApp/logs/logs.types";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useMutation, useQueryClient } from "react-query";

export const useCreateRecipeLog = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, CreateRecipeLogData>(
    (data: CreateRecipeLogData) => MyFitAppApi.createRecipeLog({ accessToken, data }),
    {
      onSuccess: ({ date }) => {
        showSuccess({ text: "recipe log was created successfully" });
        queryClient.invalidateQueries(['logs', date]);
      },
      onError: () => {
        showError({ text: "recipe log could not be created" });
      },
    }
  );

  return {
    ...mutation,
    createRecipeLog: (data: CreateRecipeLogData) => mutate(data),
  };
};
