import { FC, useEffect } from "react";
import {
  LogCategoryTabs as LogCategoryTabsComponent,
  LogCategoryTabItem,
} from "../../components";
import { FoodLogSection, RecipeLogSection, CustomLogSection } from "..";

const TABS: LogCategoryTabItem[] = [
  {
    label: "Recipes",
    value: "recipes",
  },
  {
    label: "Foods",
    value: "foods",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export interface LogCategoryTabsProps {
  currentTab: string;
  onUnknownTab: (currentTab: string) => void;
  onChangeTab: (tab: string) => void;
}

export const LogCategoryTabs: FC<LogCategoryTabsProps> = ({
  currentTab,
  onUnknownTab,
  onChangeTab,
}) => {
  useEffect(() => {
    if (onUnknownTab) {
      const isUnknownTab = !TABS.some((tab) => tab.value === currentTab);
      if (isUnknownTab) {
        onUnknownTab(currentTab);
      }
    }
  }, [currentTab, onUnknownTab]);

  return (
    <>
      <LogCategoryTabsComponent
        currentTab={currentTab}
        tabs={TABS}
        onChange={onChangeTab}
      />
      {currentTab === "recipes" ? (
        <RecipeLogSection />
      ) : currentTab === "foods" ? (
        <FoodLogSection />
      ) : (
        currentTab === "custom" && <CustomLogSection />
      )}
    </>
  );
};
