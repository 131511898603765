import MyFitAppApi, { UpdateRecipeData } from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useQueryClient, useMutation } from "react-query";

export const useUpdateRecipe = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, UpdateRecipeData>(
    (data: UpdateRecipeData) => MyFitAppApi.updateRecipe({ accessToken, data }),
    {
      onSuccess: () => {
        showSuccess({ text: "recipe updated successfully" });
        queryClient.invalidateQueries("recipes");
      },
      onError: () => {
        showError({ text: "recipe could not updated" });
      },
    }
  );

  return {
    ...mutation,
    updateRecipe: (recipe: UpdateRecipeData) => mutate(recipe),
  };
};
