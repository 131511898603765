export type FoodLogCategory =
  | "BREAKFAST"
  | "MORNING_SNACK"
  | "LUNCH"
  | "AFTERNOON_SNACK"
  | "DINNER"
  | "NIGHT_SNACK";

  export type FoodLogCategoryItem = { id: FoodLogCategory, label: string }

  export const FOOD_LOG_CATEGORIES: { [key: string]: FoodLogCategoryItem } = {
    BREAKFAST: {
      id: "BREAKFAST",
      label: "Breakfast",
    },
    MORNING_SNACK: {
      id: "MORNING_SNACK",
      label: "Morning Snack",
    },
    LUNCH: {
      id: "LUNCH",
      label: "Lunch",
    },
    AFTERNOON_SNACK: {
      id: "AFTERNOON_SNACK",
      label: "Afternoon Snack",
    },
    DINNER: {
      id: "DINNER",
      label: "Dinner",
    },
    NIGHT_SNACK: {
      id: "NIGHT_SNACK",
      label: "Night Snack",
    },
  }