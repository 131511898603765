import { FC, MouseEventHandler } from "react";
import {
  IconButton,
  ListItemButton,
  ListItemButtonProps,
  ListItemSecondaryAction,
  ListItemText,
  ListItemTextProps,
} from "@components/material";
import { Delete, Edit } from "@mui/icons-material";
import { ListItemAvatar, ListItemAvatarProps } from "@components";

export interface ListItemProps extends ListItemButtonProps {
  listItemText: ListItemTextProps;
  listItemAvatar: ListItemAvatarProps;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const ListItem: FC<ListItemProps> = ({
  listItemText,
  listItemAvatar,
  onEdit,
  onDelete,
  ...props
}) => {

  const handleEditItem: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (onEdit) {
      onEdit();
    }
  };

  const handleDeleteItem: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (onDelete) {
      onDelete();
    }
  };

  return (
    <ListItemButton dense {...props}>
      <ListItemAvatar {...listItemAvatar} />
      <ListItemText {...listItemText} />
      <ListItemSecondaryAction>
        {onEdit && (
          <IconButton onClick={handleEditItem}>
            <Edit />
          </IconButton>
        )}
        {onDelete && (
          <IconButton onClick={handleDeleteItem}>
            <Delete />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};
