import { useState } from "react";

type StoredStateConfig<T> = {
  storage: Storage;
  defaultValue: T | null;
};

function useStoredState<T>(
  key: string,
  config?: StoredStateConfig<T>
): [value: T, setter: (value: T) => void] {
  const { defaultValue = null, storage = localStorage } = config || {};

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      console.log(error);
      return defaultValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore =
        typeof value === "object" && !Array.isArray(value)
          ? { ...storedValue, ...value }
          : value;
      storage.setItem(key, JSON.stringify(valueToStore));
      setStoredValue(valueToStore);
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export default useStoredState;
