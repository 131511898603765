import { List } from "@components/material";
import { LinearProgress } from "@mui/material";
import { FC } from "react";
import { Recipe } from "@modules/Recipes/types";
import { RecipesListItem } from "./components";

export interface RecipesListProps {
  items: Recipe[];
  pending: boolean;
  onClickItem?: (recipe: Recipe) => void;
  onDelete?: (recipe: Recipe) => void;
  onEdit?: (recipe: Recipe) => void;
  onCreateImage?: (recipe: Recipe, image: string) => void;
  onDeleteImage?: (recipeId: number, imageId: number) => void;
}

export const RecipesList: FC<RecipesListProps> = ({
  pending,
  items,
  onClickItem,
  onDelete,
  onEdit,
  onCreateImage,
  onDeleteImage
}) => {
  return (
    <List sx={{ p: 1 }}>
      {pending ? (
        <LinearProgress />
      ) : (
        items.map((recipe) => {
          return (
            <RecipesListItem
              key={recipe.id}
              recipe={recipe}
              onClick={onClickItem}
              onDelete={onDelete}
              onEdit={onEdit}
              onCreateImage={onCreateImage}
              onDeleteImage={onDeleteImage}
            />
          );
        })
      )}
    </List>
  );
};
