import {
  Button,
  Column,
  ScrollContainer,
  SearchField,
} from "@components";
import { useSearchValue } from "@hooks";
import { RecipesList, RecipesListProps } from "@modules/Recipes/components";
import { FC, useMemo } from "react";

export interface RecipeLogSectionProps extends RecipesListProps {
  onCreateMissingFood: (data: { name: string }) => void;
}

export const RecipeLogSection: FC<RecipeLogSectionProps> = ({
  pending,
  onCreateMissingFood,
  items,
  ...listProps
}) => {
  const { searchValue, setSearchValue, clearSearchValue } = useSearchValue();

  const filteredRecipes = useMemo(
    () =>
      items.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      }),
    [searchValue, items]
  );

  return (
    <Column sx={{ overflowY: "hidden" }}>
      <SearchField
        sx={{ mt: 1, mr: 1, ml: 1 }}
        onSearch={setSearchValue}
        onClear={clearSearchValue}
        value={searchValue}
      />
      <ScrollContainer sx={{ p: 1, pt: 1 }}>
        {filteredRecipes.length > 0 && (
          <RecipesList
            pending={pending}
            items={filteredRecipes}
            {...listProps}
          />
        )}
        {!pending && searchValue !== "" && (
          <Button
            sx={{ mt: 1 }}
            onClick={() => onCreateMissingFood({ name: searchValue })}
          >
            Create Food '{searchValue}'
          </Button>
        )}
      </ScrollContainer>
    </Column>
  );
};
