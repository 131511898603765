import { FC } from "react";
import {
  ListItemSecondaryAction as MaterialListItemSecondaryAction,
  ListItemSecondaryActionProps as MaterialListItemSecondaryActionProps,
} from "@mui/material";

export interface ListItemSecondaryActionProps
  extends MaterialListItemSecondaryActionProps {}

export const ListItemSecondaryAction: FC<ListItemSecondaryActionProps> = ({
  children,
  ...props
}) => {
  return (
    <MaterialListItemSecondaryAction {...props}>
      {children}
    </MaterialListItemSecondaryAction>
  );
};
