export type Macros = {
    calories: number;
    protein: number;
    carbs: number;
    fats: number;
  };

export const DEFAULT_MACROS = {
  calories: 0,
  protein: 0,
  carbs: 0,
  fats: 0,
}