import { LogCategoryTabs } from "@modules/Log";
import { ErrorNavigate, useErrorPage } from "@modules/Error";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GoBackPage } from "@components";

const LogPage: FC = () => {
  const navigate = useNavigate();
  const { navigateToErrorPage } = useErrorPage();
  const { logType } = useParams<"logType">();

  const handleUnknownTab = (unknownTab: string) => {
    navigateToErrorPage(`Log Tab '${unknownTab}' ist unbekannt`);
  };

  const handleChangeTab = (tab: string) => {
    navigate(`/log/${tab}`);
  };

  if (!logType) {
    return <ErrorNavigate message={`Log Tab '${logType}' ist unbekannt`} />;
  }

  return (
    <GoBackPage path="/">
      <LogCategoryTabs
        currentTab={logType}
        onUnknownTab={handleUnknownTab}
        onChangeTab={handleChangeTab}
      />
    </GoBackPage>
  );
};

export default LogPage;
