import {
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
} from "@mui/material";
import { FC } from "react";

export interface ButtonProps extends MaterialButtonProps {}

export const Button: FC<ButtonProps> = ({
  sx,
  color = "primary",
  children,
  ...props
}) => {
  return (
    <MaterialButton
      color={color}
      variant="contained"
      sx={{ borderRadius: 5, ...sx }}
      {...props}
    >
      {children}
    </MaterialButton>
  );
};
