import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { FoodLog } from "@modules/Log/types";
import { useQueryClient, useMutation } from "react-query";

export type UseDeleteFoodLogConfig = {
  date: string;
};

export const useDeleteFoodLog = ({ date } : UseDeleteFoodLogConfig) => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, number>(
    (foodLogId: number) => MyFitAppApi.deleteFoodLog({ accessToken, foodLogId }),
    {
      onSuccess: () => {
        showSuccess({ text: "food log deleted successfully" });
        queryClient.invalidateQueries(["logs", date]);
      },
      onError: () => {
        showError({ text: "food log could not deleted" });
      },
    }
  );

  return {
    ...mutation,
    deleteFoodLog: (foodLog: FoodLog) => mutate(foodLog.id),
  };
};
