import imageCompression from "browser-image-compression";

export const decodeBase64 = (str: string) => {
  return decodeURIComponent(window.atob(str));
};

export const convertToBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      }
    };
    reader.readAsDataURL(file);
  });

export const trimBase64 = (str: string) => {
  const searchString = "base64,";
  const pos = str.search(searchString);
  return str.substring(pos + searchString.length, str.length);
};

export const compressImage = async (image: File) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 600,
    useWebWorker: true,
  };
  return await imageCompression(image, options);
};

export const convertImage = async (file: File): Promise<string> => {
  const compressedFile = await compressImage(file);
  return trimBase64(await convertToBase64(compressedFile));
};
