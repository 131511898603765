import { CreateRecipeImageData } from "..";
import { fetchApi } from "../utils";
import { CreateRecipeData, UpdateRecipeData } from "./recipes.types";

// --- CREATE ------------

export const createRecipe = async ({
  accessToken,
  data,
}: {
  accessToken: string;
  data: CreateRecipeData;
}) => {
  const { recipe } = await fetchApi(`/recipes`, {
    method: "POST",
    accessToken,
    body: data,
  });
  return recipe;
};

export const createRecipeImage = async ({
  accessToken,
  data,
}: {
  accessToken: string;
  data: CreateRecipeImageData;
}) => {
  const { image } = await fetchApi(`/recipes/${data.recipeId}/images`, {
    method: "POST",
    accessToken,
    body: {
      image: data.image,
    },
  });
  return image;
};

// --- READ   ------------

export const getRecipes = async ({ accessToken }: { accessToken: string }) => {
  const { recipes } = await fetchApi(`/recipes`, {
    accessToken,
  });
  return recipes;
};

// --- UPDATE ------------

export const updateRecipe = async ({
  accessToken,
  data,
}: {
  accessToken: string;
  data: UpdateRecipeData;
}) => {
  const { recipe } = await fetchApi(`/recipes/${data.id}`, {
    method: "PUT",
    accessToken,
    body: data,
  });
  return recipe;
};

// --- DELETE ------------

export const deleteRecipe = async ({
  accessToken,
  recipeId,
}: {
  accessToken: string;
  recipeId: number;
}) => {
  await fetchApi(`/recipes/${recipeId}`, {
    method: "DELETE",
    accessToken,
  });
};

export const deleteRecipeImage = async ({
  accessToken,
  recipeId,
  imageId,
}: {
  accessToken: string;
  recipeId: number;
  imageId: number;
}) => {
  await fetchApi(`/recipes/${recipeId}/images/${imageId}`, {
    method: "DELETE",
    accessToken,
  });
};
