import MyFitAppApi from "@apis/MyFitApp";
import { UpdateFoodLogData } from "@apis/MyFitApp/logs/logs.types";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateFoodLog = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, UpdateFoodLogData>(
    (data: UpdateFoodLogData) =>
      MyFitAppApi.updateFoodLog({ accessToken, data }),
    {
      onSuccess: ({ date }) => {
        showSuccess({ text: "food log was updated successfully" });
        queryClient.invalidateQueries(["logs", date]);
      },
      onError: () => {
        showError({ text: "food log could not be updated" });
      },
    }
  );

  return {
    ...mutation,
    updateFoodLog: (data: UpdateFoodLogData) => mutate(data),
  };
};
