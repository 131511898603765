import { Column, IconButton, Row, Text } from "@components";
import { FoodFormData, FoodUnitFormData } from "@modules/Foods/types";
import { Add } from "@mui/icons-material";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { UnitEditListItem } from "./components";

export interface UnitEditListProps {
  units: FoodUnitFormData[];
  register: UseFormRegister<FoodFormData>;
  onAdd: (data: FoodUnitFormData) => void;
  onRemove: (index: number) => void;
}

export const UnitEditList: FC<UnitEditListProps> = ({
  units,
  register,
  onAdd,
  onRemove,
}) => {
  const handleAdd = () => {
    onAdd({
      name: "",
      amount: 0,
      type: "CUSTOM",
    });
  };

  return (
    <Column>
      <Row sx={{ justifyContent: "space-between" }}>
        <Text>Units (in g)</Text>
        <IconButton color="primary" onClick={handleAdd}>
          <Add />
        </IconButton>
      </Row>
      <Column>
        {units.map((unit, i) => (
          <UnitEditListItem
            key={unit.id}
            index={i}
            unit={unit}
            register={register}
            onRemove={onRemove}
          />
        ))}
      </Column>
    </Column>
  );
};
