import { useStoredState } from "@hooks";
import { FoodLogCategory } from "@modules/Log/types";
import { createContext, FC, ReactNode, useContext } from "react";

interface LogContextValue {
  logData: LogData | undefined;
  setLogData: (logData: LogData) => void;
}

interface LogProviderProps {
  children: ReactNode;
}

export type LogData = {
  category: FoodLogCategory;
  date: string;
};

const LogContext = createContext<LogContextValue | undefined>(undefined);

const useLogContextValue = () => {
  const [logData, setLogData] = useStoredState<LogData | undefined>(
    "logConfig",
    { defaultValue: undefined, storage: sessionStorage }
  );

  return {
    logData,
    setLogData,
  };
};

export const LogProvider: FC<LogProviderProps> = ({ children }) => {
  const value = useLogContextValue();

  return <LogContext.Provider value={value}>{children}</LogContext.Provider>;
};

export const useLogContext = () => {
  const context = useContext(LogContext);

  if (!context) throw new Error("LogContext must be inside a LogProvider");

  const { logData, setLogData } = context;

  return { date: logData?.date, category: logData?.category, setLogData };
};
