import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useMutation } from "react-query";
import { LoginFormData } from "@modules/Auth/components";

export const useLogin = () => {
  const { setAccessToken } = useAccessTokenContext();
  const { showError } = useAlert();

  const mutation = useMutation<string, unknown, LoginFormData>(
    MyFitAppApi.login,
    {
      onSuccess: (accessToken) => {
        setAccessToken(accessToken);
      },
      onError: () => {
        showError({ text: "Login failed" });
      },
    }
  );

  const login = (data: LoginFormData) => {
    mutation.mutate(data);
  };

  return {
    ...mutation,
    login,
  };
};
