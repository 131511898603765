import { Column, ColumnProps } from "@components";
import { FC } from "react";

export interface FormProps extends ColumnProps {}

export const Form: FC<FormProps> = ({ children, ...props }) => {
  return (
    <Column component="form" {...props}>
      {children}
    </Column>
  );
};
