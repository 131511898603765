import { UpdateFoodData } from "@apis/MyFitApp";
import { CreateFoodLogData } from "@apis/MyFitApp/logs/logs.types";
import {
  Button,
  Column,
  ScrollContainer,
  SearchField,
} from "@components";
import { useSearchValue } from "@hooks";
import { FoodsListProps } from "@modules/Foods/components";
import { FoodsList } from "@modules/Foods/components";
import { FC, useMemo } from "react";

export interface FoodLogSectionProps extends FoodsListProps {
  pending: boolean;
  onUpdate: (food: UpdateFoodData) => void;
  onCreateFoodLog: (foodLog: CreateFoodLogData) => void;
  onCreateMissingFood: (data?: { name: string }) => void;
}

export const FoodLogSection: FC<FoodLogSectionProps> = ({
  pending,
  items,
  onUpdate,
  onCreateFoodLog,
  onCreateMissingFood,
  ...listProps
}) => {
  const { searchValue, setSearchValue, clearSearchValue } = useSearchValue();

  const filteredFoods = useMemo(() => {
    if(searchValue === "") return items
    const lcSearchValue = searchValue.toLowerCase();
    return items
      .filter((item) => {
        if (item.name.toLowerCase().includes(lcSearchValue)) return true;
        if (
          item.units.some((unit) =>
            unit.name.toLowerCase().includes(lcSearchValue)
          )
        )
          return true;
        return false;
      })
      .map((item) => {
        let index = 0;
        if (item.name.toLowerCase() === lcSearchValue) {
          index = 3;
        }
        if (item.name.toLowerCase().startsWith(lcSearchValue)) {
          index = 2;
        }
        return {
          index,
          item,
        };
      })
      .sort((a, b) => {
        return a.index < b.index ? 1 : -1;
      })
      .map((item) => item.item);
  }, [searchValue, items]);

  return (
    <Column sx={{ overflowY: "hidden" }}>
      <SearchField
        sx={{ mt: 1, mr: 1, ml: 1 }}
        onSearch={setSearchValue}
        onClear={clearSearchValue}
        value={searchValue}
      />
      <ScrollContainer sx={{ p: 1, pt: 1 }}>
        {filteredFoods.length > 0 && (
          <FoodsList pending={pending} items={filteredFoods} {...listProps} />
        )}
        {!pending && searchValue !== "" && (
          <Button
            sx={{ mt: 1 }}
            onClick={() => onCreateMissingFood({ name: searchValue })}
          >
            Create Food '{searchValue}'
          </Button>
        )}
      </ScrollContainer>
    </Column>
  );
};
