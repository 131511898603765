import { fetchApi } from "../utils";

export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const { accessToken } = await fetchApi("/auth/login", {
    method: "POST",
    body: {
      email,
      password,
    },
  });
  return accessToken;
};

export const signUp = async ({
  email,
  password,
  firstname,
  lastname,
  inviteKey,
}: {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  inviteKey: string;
}) => {
  await fetchApi("/auth/signup", {
    method: "POST",
    body: {
      email,
      password,
      firstname,
      lastname,
      inviteKey,
    },
  });
};

export const forgotPassword = async ({ email }: { email: string }) => {
  await fetchApi("/auth/forgotPassword", {
    method: "POST",
    body: {
      email,
    },
  });
};

export const resetPassword = async ({
  resetToken,
  password,
}: {
  resetToken: string;
  password: string;
}) => {
  await fetchApi("/auth/resetPassword", {
    method: "PUT",
    body: {
      resetToken,
      password,
    },
  });
};
