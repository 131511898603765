import { FC } from "react";
import { useLogin } from "@modules/Auth/hooks";
import { LoginForm as LoginFormComponent } from "@modules/Auth/components";

export const LoginForm: FC = () => {
  const { isLoading, login } = useLogin();

  return (
    <LoginFormComponent pending={isLoading} onSubmit={(data) => login(data)} />
  );
};
