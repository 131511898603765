import { CreateFoodData, UpdateFoodData } from "@apis/MyFitApp";
import { FoodDiet, FoodFormData } from "@modules/Foods/types";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";

export interface UseFoodFormConfig {
    defaultValues?: FoodFormData;
    onCreate: (food: CreateFoodData) => void;
    onUpdate: (food: UpdateFoodData) => void;
}

export const useFoodForm = ({ defaultValues, onCreate, onUpdate }: UseFoodFormConfig) => {
    const {
        handleSubmit,
        register,
        control,
        watch,
        setValue,
        formState: { errors },
      } = useForm<FoodFormData>({
        defaultValues,
      });

      const {
        fields: units,
        append: addUnit,
        remove: removeUnit,
      } = useFieldArray({
        control,
        name: "units",
      });
    
      const diet = watch("diet");
      const protein = watch("protein") || 0;
      const carbs = watch("carbs") || 0;
      const fats = watch("fats") || 0;
    
      const changeDiet = (diet: FoodDiet) => {
        setValue("diet", diet);
      };
    
      const submit: SubmitHandler<FoodFormData> = (food) => {
        if (food.id !== undefined) {
          if (onUpdate) {
            onUpdate({
              ...food,
              id: food.id,
            });
          }
        } else {
          if (onCreate) {
            onCreate(food);
          }
        }
      }
    
      const calories = Math.floor(4.1 * (protein + carbs) + 9.3 * fats);
    
      return {
        calories,
        diet,
        units,
        addUnit,
        removeUnit,
        changeDiet,
        register,
        errors,
        handleSubmit: handleSubmit(submit),
      }
}