export const formatDate = (
  date: Date | undefined,
  config: Intl.DateTimeFormatOptions
) => {
  if (!(date instanceof Date)) return "-";
  return date.toLocaleDateString("de", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    ...config,
  });
};

export const formatWeekday = (date: Date | undefined) => {
  if (!(date instanceof Date)) return "-";
  return date.toLocaleDateString("de", { weekday: "long" });
};

export const formatBodyWeight = (weight: number | undefined) => {
  return (
    (weight
      ? Intl.NumberFormat("de", { minimumFractionDigits: 1 }).format(weight)
      : "-") + "kg"
  );
};

export const formatBodyFat = (bodyFat: number | undefined) => {
  return (
    (bodyFat
      ? Intl.NumberFormat("de", {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(bodyFat)
      : "-") + "%"
  );
};

export const formatWeight = (weight: number | undefined) => {
  if (!weight) return "-";
  return (
    Intl.NumberFormat("de", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(weight) + "kg"
  );
};

export const formatCalories = (calories: number | undefined) => {
  if (!calories) return "-";
  return (
    Intl.NumberFormat("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(calories) + "kcal"
  );
};

export const formatProtein = (protein: number | undefined) => {
  if (!protein) return "-";
  return (
    Intl.NumberFormat("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(protein) + "p"
  );
};

export const formatCarbs = (carbs: number | undefined) => {
  if (!carbs) return "-";
  return (
    Intl.NumberFormat("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(carbs) + "c"
  );
};

export const formatFats = (fats: number | undefined) => {
  if (!fats) return "-";
  return (
    Intl.NumberFormat("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(fats) + "f"
  );
};

export const formatNumber = (value: number | undefined) => {
  if (!value) return "-";
  return (
    Intl.NumberFormat("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)
  );
}

export const formatDiffNumber = (value: number | undefined) => {
  if (!value) return "-";
  return (
    Intl.NumberFormat("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      signDisplay: "always"
    }).format(value)
  );
}
