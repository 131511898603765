import { GoBackPage } from "@components";
import { RecipesList, CreateRecipeFab } from "@modules/Recipes";
import { FC } from "react";

const RecipesPage: FC = () => {
  return (
    <GoBackPage title="Recipes">
      <RecipesList />
      <CreateRecipeFab />
    </GoBackPage>
  );
};

export default RecipesPage;
