import { useState } from "react";

const useDialog = (defaultValue = false) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return { isOpen, closeDialog, openDialog };
};

export default useDialog;
