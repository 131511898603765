import { IconButton, Row, TextField } from "@components";
import { FoodFormData, FoodUnitFormData } from "@modules/Foods/types";
import { Remove } from "@mui/icons-material";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";

export interface UnitEditListItemProps {
  register: UseFormRegister<FoodFormData>;
  index: number;
  unit: FoodUnitFormData;
  onRemove: (index: number) => void;
}

export const UnitEditListItem : FC<UnitEditListItemProps> = ({ index, unit, register, onRemove }) => {

  const handleRemove = () => () => {
    onRemove(index);
  };

  const isStandard = unit.type === "STANDARD";

  return (
    <Row>
      <TextField
        placeholder="g"
        type="number"
        sx={{ width: 100 }}
        margin="dense"
        size="small"
        {...register(`units.${index}.amount`, {
          required: "Amount required",
          min: { value: 1, message: "Value must be greater than 0" },
          valueAsNumber: true,
        })}
        disabled={isStandard}
      />
      <TextField
        placeholder="Name"
        fullWidth
        margin="dense"
        size="small"
        sx={{ ml: 1 }}
        {...register(`units.${index}.name`, {
          required: "Name required",
        })}
        disabled={isStandard}
      />
      {!isStandard && (
        <IconButton color="error" onClick={handleRemove}>
          <Remove />
        </IconButton>
      )}
    </Row>
  );
};
