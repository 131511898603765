import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { Recipe } from "@modules/Recipes/types";
import { useQueryClient, useMutation } from "react-query";

export const useDeleteRecipe = () => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, number>(
    (recipeId: number) => MyFitAppApi.deleteRecipe({ accessToken, recipeId }),
    {
      onSuccess: () => {
        showSuccess({ text: "recipe deleted successfully" });
        queryClient.invalidateQueries("recipes");
      },
      onError: () => {
        showError({ text: "recipe could not deleted" });
      },
    }
  );

  return {
    ...mutation,
    deleteRecipe: (recipe: Recipe) => mutate(recipe.id),
  };
};
