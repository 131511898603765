import { Text, TextField } from "@components";
import { Food } from "@modules/Foods/types";
import { Photo } from "@mui/icons-material";
import { Autocomplete, Avatar, Box } from "@mui/material";
import { ChangeEventHandler, FC, useState } from "react";

export interface IngredientAutocompleteProps {
  foods: Food[];
  onSelect: (food: Food) => void;
}

export const IngredientAutocomplete: FC<IngredientAutocompleteProps> = ({
  foods,
  onSelect,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleChangeSearchValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.currentTarget.value);
  };

  const handleSelectFood = (e: React.SyntheticEvent, food: Food | null) => {
    if (food && onSelect) {
      onSelect(food);
      setSearchValue("");
    }
  };

  return (
    <Autocomplete
      options={foods}
      autoHighlight
      getOptionLabel={(option) => option.name}
      onChange={handleSelectFood}
      renderOption={(props, food) => (
        <Box component="li" sx={{ ml: 1, mr: 1 }} {...props}>
          <Avatar
            sx={{ width: 24, height: 24 }}
            src={food.images.length > 0 ? food.images[0].src : ""}
          >
            <Photo sx={{ width: 18, height: 18 }} />
          </Avatar>
          <Text sx={{ ml: 1 }}>{food.name}</Text>
        </Box>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Search food..."
            onChange={handleChangeSearchValue}
            inputProps={{
              ...params.inputProps,
              value: searchValue,
            }}
          />
        );
      }}
    />
  );
};
