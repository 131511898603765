import { useEditFoodLogContext } from "@modules/Log/contexts";
import { useCreateFoodLog, useUpdateFoodLog } from "@modules/Log/hooks";
import { FC } from "react";
import { FoodLogDialog as FoodLogDialogComponent } from "../../components";
export interface FoodLogDialogProps {}

export const FoodLogDialog: FC<FoodLogDialogProps> = () => {
  const { isOpen, foodLog, resetFoodLog } = useEditFoodLogContext();
  const { createFoodLog } = useCreateFoodLog()
  const { updateFoodLog } = useUpdateFoodLog()

  const title = foodLog?.food?.name || ''
  const foodUnits = foodLog?.food?.units || []

  return (
    <FoodLogDialogComponent
      open={isOpen}
      title={title}
      foodLog={foodLog}
      foodUnits={foodUnits}
      onCreate={createFoodLog}
      onUpdate={updateFoodLog}
      onClose={resetFoodLog}
    />
  );
};
