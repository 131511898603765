import { CreateRecipeLogData } from "@apis/MyFitApp/logs/logs.types";
import { Dialog } from "@components";
import { RecipeLogFormData } from "@modules/Log/types";
import { DialogTitle } from "@mui/material";
import { FC } from "react";
import { RecipeLogForm } from "../RecipeLogForm/RecipeLogForm";

export interface RecipeLogDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onCreate: (data: CreateRecipeLogData) => void;
  recipeLog?: RecipeLogFormData;
}

export const RecipeLogDialog: FC<RecipeLogDialogProps> = ({
  open,
  title,
  onClose,
  onCreate,
  recipeLog,
}) => {
  const handleCreate = (data: CreateRecipeLogData) => {
    onClose();
    onCreate(data);
  };

  return (
    <Dialog open={open} onBackdropClick={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <RecipeLogForm
        defaultValues={recipeLog}
        onCreate={handleCreate}
      />
    </Dialog>
  );
};
