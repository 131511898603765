import { RecipeDialog } from "@modules/Recipes/containers";
import {
  IngredientFormData,
  Recipe,
  RecipeFormData,
} from "@modules/Recipes/types";
import { createContext, FC, ReactNode, useContext, useState } from "react";

interface EditRecipeContextValue {
  isOpen: boolean;
  recipe?: RecipeFormData | null;
  createRecipe: (data?: {
    name?: string;
    ingredients?: IngredientFormData[];
  }) => void;
  updateRecipe: (recipe: Recipe) => void;
  resetRecipe: () => void;
}

const EditRecipeContext = createContext<EditRecipeContextValue | undefined>(
  undefined
);

const useEditRecipeValue = () => {
  const [recipe, setRecipe] = useState<RecipeFormData | null | undefined>(
    undefined
  );

  const createRecipe = (data?: {
    name?: string;
    ingredients?: IngredientFormData[];
  }) => {
    if (data) {
      setRecipe({
        name: data?.name || "",
        description: "",
        ingredients: data?.ingredients || [],
      });
    } else {
      setRecipe(null);
    }
  };

  const updateRecipe = (recipe: Recipe) => {
    setRecipe(recipe);
  };

  const resetRecipe = () => {
    setRecipe(undefined);
  };

  const isOpen = recipe !== undefined;

  return {
    isOpen,
    recipe,
    createRecipe,
    updateRecipe,
    resetRecipe,
  };
};

interface EditRecipeProviderProps {
  children: ReactNode;
}

export const EditRecipeProvider: FC<EditRecipeProviderProps> = ({
  children,
}) => {
  const value = useEditRecipeValue();

  return (
    <EditRecipeContext.Provider value={value}>
      {children}
      <RecipeDialog />
    </EditRecipeContext.Provider>
  );
};

export const useEditRecipeContext = () => {
  const context = useContext(EditRecipeContext);

  if (!context)
    throw new Error("EditRecipeContext must be inside a EditRecipeProvider");

  return context;
};
