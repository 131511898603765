import { Button, Page, Text } from "@components";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ErrorPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { message = "Unbekannter Fehler" } = location.state as { message: string }

  const handleGoToMainPage = () => {
    navigate("/");
  };

  return (
    <Page>
      <Text>An error occured</Text>
      <Text>{message}</Text>
      <Button onClick={handleGoToMainPage}>Zur Startseite</Button>
    </Page>
  );
};

export default ErrorPage;
