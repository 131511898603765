import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { useAlert } from "@hooks";
import { useQueryClient, useMutation } from "react-query";

export type UseDeleteRecipeLogConfig = {
  date: string;
};

export const useDeleteRecipeLog = ({ date } : UseDeleteRecipeLogConfig) => {
  const { accessToken } = useAccessTokenContext();
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useAlert();

  const { mutate, ...mutation } = useMutation<any, unknown, { recipeId: number, category: string }>(
    (data) => MyFitAppApi.deleteRecipeLog({ accessToken, date, ...data }),
    {
      onSuccess: () => {
        showSuccess({ text: "recipe log deleted successfully" });
        queryClient.invalidateQueries(["logs", date]);
      },
      onError: () => {
        showError({ text: "recipe log could not deleted" });
      },
    }
  );

  return {
    ...mutation,
    deleteRecipeLog: (data: { recipeId: number, category: string }) => mutate(data),
  };
};
