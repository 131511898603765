import { useState } from 'react'

export const useSearchValue = () => {
    const [searchValue, setSearchValue] = useState<string>("");

    const clearSearchValue = () => {
        setSearchValue('')
    }

    return {
        searchValue,
        setSearchValue,
        clearSearchValue,
    }
}