import { Chip } from "@mui/material";
import { Row, RowProps, Text } from "@components";
import { FC, forwardRef } from "react";
import {
  formatCalories,
  formatCarbs,
  formatFats,
  formatProtein,
} from "@utils/format";

type Macros = {
  calories: number;
  protein: number;
  carbs: number;
  fats: number;
};

export interface MacroChipsProps extends RowProps {
  macros: Macros;
  highlight?: boolean;
  label?: string;
}

export const MacroChips: FC<MacroChipsProps> = forwardRef(({ label, macros, highlight = false, ...props }, ref) => {
  return (
    <Row ref={ref} {...props}>
      {label && <Text sx={{ mr: 1 }}>{label}</Text>}
      <Chip
        variant="outlined"
        size="small"
        label={formatCalories(macros.calories)}
        sx={{ width: 80 }}
        color={highlight ? macros.calories >= 0 ? 'success' : 'error' : 'default'}
      />
      <Chip
        variant="outlined"
        size="small"
        label={formatProtein(macros.protein)}
        sx={{ width: 50 }}
        color={highlight ? macros.protein >= 0 ? 'success' : 'error' : 'default'}
      />
      <Chip
        variant="outlined"
        size="small"
        label={formatCarbs(macros.carbs)}
        sx={{ width: 50 }}
        color={highlight ? macros.carbs >= 0 ? 'success' : 'error' : 'default'}
      />
      <Chip
        variant="outlined"
        size="small"
        label={formatFats(macros.fats)}
        sx={{ width: 50 }}
        color={highlight ? macros.fats >= 0 ? 'success' : 'error' : 'default'}
      />
    </Row>
  );
})
