import { FC } from "react";
import { RecipeLogDialog as RecipeLogDialogComponent } from "@modules/Log/components";
import { useEditRecipeLogContext } from "@modules/Log/contexts/EditRecipeLogContext/EditRecipeLogContext";
import { useCreateRecipeLog } from "@modules/Log/hooks";

export interface RecipeLogDialogProps {}

export const RecipeLogDialog: FC<RecipeLogDialogProps> = () => {
  const { isOpen, resetRecipeLog, recipeLog } = useEditRecipeLogContext();
  const { createRecipeLog } = useCreateRecipeLog()

  const title = recipeLog?.recipe.name || ''

  return (
    <RecipeLogDialogComponent
      open={isOpen}
      title={title}
      onCreate={createRecipeLog}
      onClose={resetRecipeLog}
      recipeLog={recipeLog}
    />
  );
};
