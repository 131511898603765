import MyFitAppApi from "@apis/MyFitApp";
import { useAccessTokenContext } from "@contexts";
import { DEFAULT_MACROS } from "@modules/Foods/types";
import { NutritionTarget } from "@modules/Targets/types";
import { useQuery } from "react-query";

type GetCurrentTargetsConfig = {
  date: string;
}

const defaultNutritionTarget = {
  id: -1,
  startDate: "0000-00-00",
  endDate: "0000-00-00",
  macros: DEFAULT_MACROS
}

export const useGetCurrentTargets = ({ date } : GetCurrentTargetsConfig) => {
    const { accessToken } = useAccessTokenContext();
    const { data = { nutritionTarget: defaultNutritionTarget }, ...query } = useQuery<{ nutritionTarget: NutritionTarget }>("currentTargets", () =>
      MyFitAppApi.getCurrentTargets({ accessToken, date })
    );

    const { nutritionTarget } = data

    return { ...query, nutritionTarget };
}