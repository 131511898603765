import {
  IconButton as MaterialIconButton,
  IconButtonProps as MaterialIconButtonProps,
} from "@mui/material";
import { FC } from "react";

export interface IconButtonProps extends MaterialIconButtonProps {}

export const IconButton: FC<IconButtonProps> = ({ children, ...props }) => {
  return <MaterialIconButton {...props}>{children}</MaterialIconButton>;
};
