import { Box, BoxProps } from "@mui/material";
import { FC, forwardRef } from "react";

export interface RowProps extends BoxProps {}

export const Row: FC<RowProps> = forwardRef(({ sx, children, ...props }, ref) => {
  return (
    <Box ref={ref} sx={{ display: "flex", alignItems: "center", ...sx }} {...props}>
      {children}
    </Box>
  );
});
