import { Fab, FabProps } from "@components";
import { Add } from "@mui/icons-material";
import { FC } from "react";

export interface CreateFoodFabProps extends FabProps {}

export const CreateFoodFab: FC<CreateFoodFabProps> = (props) => {
  return (
    <Fab {...props}>
      <Add />
    </Fab>
  );
};
