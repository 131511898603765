import { FC } from "react";
import { Navigate } from "react-router-dom";

export interface ErrorNavigateProps {
  message: string;
}

export const ErrorNavigate: FC<ErrorNavigateProps> = ({ message }) => {
  return <Navigate to="/error" state={{ message }} />;
};
