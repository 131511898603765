import { Row, Text, TextField } from "@components";
import { FoodFormData } from "@modules/Foods/types";
import { FC } from "react";
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
} from "react-hook-form";

export interface MacrosInputProps {
  register: UseFormRegister<FoodFormData>;
  errors: FieldErrorsImpl<DeepRequired<FoodFormData>>;
  calories: number;
}

export const MacrosInput: FC<MacrosInputProps> = ({
  register,
  errors,
  calories,
}) => {
  return (
    <>
      <Text>Macros</Text>
      <Row sx={{ alignItems: "flex-start" }}>
        <TextField
          label="Calories"
          inputProps={{
            disabled: true,
          }}
          value={calories}
        />
        <TextField
          label="Protein"
          type="number"
          helperText={errors?.protein?.message}
          sx={{
            ml: 1,
          }}
          inputProps={{
            step: 0.1,
          }}
          {...register("protein", {
            required: "Protein required",
            valueAsNumber: true,
          })}
        />
        <TextField
          label="Carbs"
          type="number"
          helperText={errors?.carbs?.message}
          sx={{
            ml: 1,
          }}
          inputProps={{
            step: 0.1,
          }}
          {...register("carbs", {
            required: "Carbs required",
            valueAsNumber: true,
          })}
        />
        <TextField
          label="Fats"
          type="number"
          helperText={errors?.fats?.message}
          sx={{
            ml: 1,
          }}
          inputProps={{
            step: 0.1,
          }}
          {...register("fats", {
            required: "Fats required",
            valueAsNumber: true,
          })}
        />
      </Row>
    </>
  );
};
