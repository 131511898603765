import { SubmitHandler, useForm } from "react-hook-form";
import {
  PendingButton,
  EmailField,
  PasswordField,
  Form,
  Row,
} from "@components";
import { Link } from "react-router-dom";
import { FC } from "react";

export type LoginFormData = {
  email: string;
  password: string;
};

export interface LoginFormProps {
  onSubmit: SubmitHandler<LoginFormData>;
  pending: boolean;
}

export const LoginForm: FC<LoginFormProps> = ({ onSubmit, pending }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>();

  return (
    <Form
      aria-label="Login"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ boxShadow: 4, p: 2, borderRadius: 5 }}
    >
      <EmailField
        fullWidth
        helperText={errors?.email?.message}
        {...register("email", { required: "email required" })}
      />
      <PasswordField
        fullWidth
        helperText={errors?.password?.message}
        {...register("password", { required: "password required" })}
      />
      <PendingButton
        pending={pending}
        progressProps={{ "aria-label": "Login Progress" }}
        type="submit"
        fullWidth
      >
        Login
      </PendingButton>
      <Row sx={{ mt: 1 }}>
        <Link to="/forgotPassword" style={{ color: "text.primary" }}>
          forgot password?
        </Link>
      </Row>
    </Form>
  );
};
