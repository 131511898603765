import { Typography, TypographyProps } from "@mui/material";
import { FC } from "react";

export interface TextProps extends TypographyProps {}

export const Text: FC<TextProps> = ({ sx, children, ...props }) => {
  return (
    <Typography
      sx={{ color: "text.primary", userSelect: "none", ...sx }}
      {...props}
    >
      {children}
    </Typography>
  );
};
